import React, { useEffect, useMemo, useState } from "react";
import Logo from "../Resources/blackNwhiteLogo.png";
import footerEmailIcon from "../Resources/footerEmailIcon.png";
import footerLocationIcon from "../Resources/footerLocationIcon.png";
import footerPhoneIcon from "../Resources/footerPhoneIcon.png";
import footerLinkIcon from "../Resources/footerLinkIcon.png";
import facebookIcon from "../Resources/facebook-icon.svg";
import instagramIcon from "../Resources/instagram-icon.svg";
import linkedinIcon from "../Resources/linkedin-icon.svg";
import twitterIcon from "../Resources/twitter-icon.svg";
import { Link } from "react-router-dom";

import "./index.css";
import { footerService } from "../services/footer.service";
import { Whatsapp } from "./whatsapp-float/whatsapp.component";

const Footer = () => {

	const [meta, setMeta] = useState({})
	
	const socialMedia = useMemo(() => meta?.SocialMedia, [meta])

	const socialMediaRedirect = (url) => {
		window.open(url, "_blank");
	};

	useEffect(() => {
		footerService.getFooterData('Footer').subscribe({
			next: (res) => {
				setMeta(res)
			},
			error: (err) => {
				console.log(err);
			}
		})
	}, [])

	return (
		<>
			{socialMedia && socialMedia.Whatsapp ? <Whatsapp phNumber={socialMedia.Whatsapp} /> : null}
			<section className='bg-gray-950 pt-10'>
				<div className='w-[90%] mx-auto pb-10 lg:flex lg:items-center'>
					<div className='sm:w-[90%] sm:mx-auto lg:w-[40%] lg:mx-0'>
						<img src={Logo} alt='Webbeesite website development company in UAE' className='w-full lg:w-[40%]' />
					</div>
					<div className='sm:flex sm:justify-between lg:w-[60%] lg:justify-normal'>
						<div className='sm:w-[33%] lg:w-[20%] flex flex-col items-start justify-start'>
							<img src={footerLinkIcon} alt='' className='w-8 lg:w-6 mt-4 mb-2' />
							<h3 className='font-outfit text-white font-extrabold'>
								Useful Links
							</h3>
							<p className='font-outfit text-white/80 cursor-pointer'>
								<Link to={"/careers"}>Careers</Link> <br />
								<Link to={"/faq"}>FAQs</Link> <br />
								<Link to={"/newsEvents"}>News & Events</Link> <br />
								<Link to={"/blogs"}>Blogs</Link>
							</p>
						</div>
						<div className='sm:w-[33%] lg:w-[37%] flex flex-col items-start justify-start'>
							<img
								src={footerLocationIcon}
								alt=''
								className='w-8 lg:w-6 mt-4 mb-2'
							/>
							<h3 className='font-outfit text-white font-extrabold'>
								Our Location
							</h3>
							<p
								className='font-outfit text-white/80 cursor-pointer'
								onClick={() => socialMediaRedirect(`https://www.google.com/maps/place/WeBBeeSite+Technology+LLC/@25.1134018,55.2030081,15z/data=!4m14!1m7!3m6!1s0x3e5f6b8aa6c386ff:0x3bfff29042934825!2sWeBBeeSite+Technology+LLC!8m2!3d25.1134018!4d55.2030081!16s%2Fg%2F11sw4vf5s_!3m5!1s0x3e5f6b8aa6c386ff:0x3bfff29042934825!8m2!3d25.1134018!4d55.2030081!16s%2Fg%2F11sw4vf5s_?entry=ttu`)} //https://www.google.com/maps?q=${meta?.ContactUs?.Lan},${meta?.ContactUs?.Lat}
							>
								Office # 702, B8 Building, Al Barsha-1, UAE, United Arab
								Emirates.
							</p>
						</div>
						<div className='sm:w-[33%] lg:w-[20%] flex flex-col items-start justify-start '>
							<img
								src={footerPhoneIcon}
								alt=''
								className='w-8 lg:w-6 mt-4 mb-2'
							/>
							<h3 className='font-outfit text-white font-extrabold'>
								Customer Service
							</h3>
							<div className='font-outfit text-white/80'>
								<p>
									<a href='tel:971545280113' className="cursor-pointer hover:underline">+971 54 528 0113</a>
								</p>
								<p>
									<a href='tel:971545281135' className="cursor-pointer hover:underline">+971 54 528 1135</a>
								</p>
								<a href='tel:97143320780' className="cursor-pointer hover:underline">+971 4 332 0780</a>
							</div>
						</div>
						<div className='sm:w-[33%] lg:w-[20%] flex flex-col items-start justify-start'>
							<img
								src={footerEmailIcon}
								alt=''
								className='w-8 lg:w-6 mt-4 mb-2'
							/>
							<h3 className='font-outfit text-white font-extrabold'>
								Email Address
							</h3>
							<div className='font-outfit text-white/80 cursor-pointer'>
								<p className="hover:underline">
									<a href='mailto:support@webbeesite.com'>
										support@webbeesite.com
									</a>
								</p>
								<p className="hover:underline">
									<a href='mailto:info@webbeesite.com'>info@webbeesite.com</a>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className='border-t-[1px] bg-black border-gray-700 pt-10 xl:pt-6 pb-8 xl:pb-4'>
					<div className='w-[90%] mx-auto md:flex md:justify-between md:items-center'>
						<div className='flex flex-row gap-2'>
							<Link to={"/termsandconditions"}>
								<p className='text-white text-sm font-outfit cursor-pointer'>
									Terms & Conditions
								</p>
							</Link>
							<Link to={"/privacypolicy"}>
								<p className='text-white text-sm font-outfit cursor-pointer'>
									Privacy Policy
								</p>
							</Link>
							<Link to={"/sitemap"}>
								<p className='text-white text-sm font-outfit cursor-pointer'>
									Sitemap
								</p>
							</Link>
						</div>
						<div className='flex justify-center my-4 xl:my-0'>
							<p className='text-white/60 font-outfit'>
								WEBBEE TECHNOLOGY LLC © 2023. All Rights Reserved
							</p>
						</div>
						{socialMedia ?
						<div className='flex flex-col md:flex-row md:items-center'>
							<p className='text-white/60 text-sm font-outfit mb-2 xl:mb-0 xl:mr-1'>
								Follow us on our Socials
							</p>
							<div className='flex md:ml-2'>
								{socialMedia.Facebook ? <img 
									alt="Facebook"
									src={facebookIcon} 
									className="social w-8 fb mx-1 cursor-pointer rounded-full" 
									onClick={() => socialMediaRedirect(socialMedia.Facebook)}
								/> : null}
								{socialMedia.Instagram ? <img
									alt='Instagram'
									src={instagramIcon}
									className="social w-8 insta mx-1 cursor-pointer rounded-full" 
									onClick={() => socialMediaRedirect(socialMedia.Instagram)}
								/> : null}
								{socialMedia.Linkedin ? <img
									alt='Linkedin'
									src={linkedinIcon}
									className="social w-8 linkedin mx-1 cursor-pointer rounded-full" 
									onClick={() => socialMediaRedirect(socialMedia.Linkedin)}
								/> : null}
								{socialMedia.Twitter ? <img
									alt='Twitter'
									src={twitterIcon}
									className="social w-8 twitter mx-1 cursor-pointer rounded-full" 
									onClick={() => socialMediaRedirect(socialMedia.Twitter)}
								/> : null}
							</div> 
						</div>
						: null}
					</div>
				</div>
			</section>
		</>
	);
};

export default Footer;
