import React from "react";
import { useFormik } from "formik";
import { singUpSchema } from "../Schema";

import { toast } from 'react-toastify';
import { environment } from "../environment";


const initialValues = {
	fullName: '',
	Gender: '',
	DateOfBirth: '',
	Company: '',
	Position: '',
	Experience: '',
}

const baseURL = environment.baseUrl;

const CarreersPopup = (props) => {

	const [filePatch , setFilePatch] = React.useState('');

	const {values, errors, handleBlur, handleChange, handleSubmit, touched, isValid} = useFormik({
		validationSchema: singUpSchema,
		initialValues: initialValues,
		onSubmit: (values, action) => {
			applyForJob(values, filePatch)
			action.resetForm()
			props.setCarreerPopup(false)
		},
	})
	
	const sendCV = (file) => 
	{
		var formdata = new FormData();
		formdata.append("file", file);
		const requestOptions = 
		{
			method: 'POST',
			body : formdata
		}

		fetch(`${baseURL}/api/File/Mobile`, requestOptions)
		.then((response)=>{
			if(response.status === 200)
			{
				response.json().then((result)=>{
					setFilePatch(result.Result)
					// console.log('result => ', result.Result)
				})
			}
		})
		.catch((err)=>{
			console.log('err => ', err)
		})
	}


	const applyForJob = (formData, filePatch) =>
	{
		const requestOptions = 
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"fulName": values.fullName,
				"gender": values.Gender,
				"dob": values.DateOfBirth,
				"company": values.Company,
				"position": values.Position,
				"experience": values.Experience,
				"jobId": props.activeJob.Id,
				"garageId": localStorage.getItem('GarageId'),
				"cvPath": filePatch,
			})
		}

		fetch(`${baseURL}/api/Customer/Careers/ApplyNow`, requestOptions)
		.then((response)=>{
			if(response.status === 200)
			{
				response.json().then((result)=>{
					console.log('result => ', result)
					toast.success(result.Message)
				})
			}
		})
		.catch((err)=>{
			console.log('err => ', err)
		})
	}

	


	return (
		<main className='w-full h-screen bg-black/50 fixed inset-0 z-50 flex items-center justify-center'>
			<section className='w-[85%] xl:w-[55%] bg-white rounded-xl mx-auto py-3'>
				<div className='flex justify-between items-center p-4 sm:px-8'>
					<h1 className='font-outfit font-semibold text-2xl'>Apply Now</h1>
					<svg
						onClick={()=>{props.setCarreerPopup(false)}}

						xmlns='http://www.w3.org/2000/svg'
						height='1.5em'
						viewBox='0 0 384 512'
                        fill="currentColor"
                        className="cursor-pointer">
						<path d='M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z' />
					</svg>
				</div>
				{/* INPUTS & LABELS */}
				<form onSubmit={handleSubmit}>
					<div className='font-manrope px-4 sm:px-8 py-2 w-full'>
						<div className='font-extrabold text-sm'>Full Name</div>
						<input
							type='text'
							placeholder='Type Here'
							name="fullName"
							value={values.fullName}
							onChange={handleChange}
							onBlur={handleBlur}
							className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm mb-3'
						/>
						{ errors.fullName && touched.fullName ? <p className="text-red-600 text-xs">{errors.fullName}</p> : null}

						<div className='font-extrabold text-sm'>Gender</div>
						{/* <input
							type='text'
							placeholder='Type Here'
							name="Gender"
							value={values.Gender}
							onChange={handleChange}
							onBlur={handleBlur}
							className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm mb-3'
						/>
						{ errors.Gender && touched.Gender ? <p className="text-red-600 text-xs">{errors.Gender}</p> : null} */}
						<select name="Gender" onChange={handleChange} onBlur={handleBlur} className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm mb-3'>
							<option selected>Select gender</option>
							<option>Male</option>
							<option>Female</option>
						</select>

						<div className='font-extrabold text-sm'>Date Of Birth</div>
						<input
							type='date'
							name="DateOfBirth"
							value={values.DateOfBirth}
							onChange={handleChange}
							onBlur={handleBlur}
							className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm mb-3'
						/>
						{ errors.DateOfBirth && touched.DateOfBirth ? <p className="text-red-600 text-xs">{errors.DateOfBirth}</p> : null}


						<div className='font-extrabold text-sm'>Company</div>
						<input
							type='text'
							placeholder='Type Here'
							name="Company"
							value={values.Company}
							onChange={handleChange}
							onBlur={handleBlur}
							className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm mb-3'
						/>
						{ errors.Company && touched.Company ? <p className="text-red-600 text-xs">{errors.Company}</p> : null}

						<div className='font-extrabold text-sm'>Position (Recent)</div>
						<input
							type='text'
							name="Position"
							value={values.Position}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder='Type Here'
							className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm mb-3'
						/>
						{ errors.Position && touched.Position ? <p className="text-red-600 text-xs">{errors.Position}</p> : null}

						<div className='font-extrabold text-sm'>Experience</div>
						<input
							type='text'
							name="Experience"
							value={values.Experience}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder='Type Here'
							className='w-full px-4 py-3 border-2 border-black/40 focus:border-[#962409] rounded-lg text-sm xl:mb-3'
						/>
						{ errors.Experience && touched.Experience ? <p className="text-red-600 text-xs">{errors.Experience}</p> : null}

					</div>
					{/* ATTACHMENT */}
					<div className='px-4 sm:px-8 py-2 sm:flex items-center justify-between'>
						<div className='my-2 sm:flex items-center'>
							<h2 className='font-manrope font-extrabold mr-4'>Attach CV</h2>
							<input 
								type='file'  
								className='font-manrope font-extrabold text-[#131313]/60 text-sm' 
								onChange={(e)=>{sendCV(e.target.files[0])}}
							/>
							{/* <small>No file chosen</small> */}
						</div>
						<button className='bg-gkYellow hover:bg-gkYellow/90 text-white font-manrope font-bold px-6 py-3 rounded-lg disabled:cursor-not-allowed disabled:bg-gkYellow/500 disabled:text-white disabled:opacity-50'
							disabled={!isValid || filePatch === '' }
						>
							APPLY NOW
						</button>
					</div>
				</form>
			</section>
		</main>
	);
};

export default CarreersPopup;
