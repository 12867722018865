import React from "react";

const Questions = () => {
	return (
		<main className="w-full">
			<details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
						How much does it cost to design a website?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'
						>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
					The cost of website depends on the scope of the project and the
					specific requirements of the client. WEBBEESITE provides customized
					solutions based on the budget and needs of the client. The starting
					price of a fully customized website including domain name, hosting,
					and development is only AED 1499.
				</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    How to choose the right website development company?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'
						>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                When choosing a Web design company, it's important to consider factors such as their experience, expertise, pricing, and the technology they are using. WEBBEESITE is powered by the most advanced Microsoft Azure servers so you can rest assure about website quality, speed, and security. 
				</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    How long does it usually take to develop a website?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                The timeline for website development is a matter of few hours with WEBBEESITE if you have your content ready. With our very own user-friendly content management system (CMS), you can get your website ready with in just 72 hours.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    Is your website design mobile-friendly?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                Yes, we prioritize responsive design to ensure your website looks great and functions well on all devices, including mobile phones and tablets.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    Do you provide website maintenance after development?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                This is the most valuable feature of our CMS: you won't have to worry about your website maintenance. We take care of everything to make sure your website stays up-to-date, secure, and functional.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    Will my website be optimized for search engines (SEO)?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                Absolutely, we build websites with SEO best practices and marketing strategies in mind to improve your website's visibility on search engines.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    How do I get started with your website development services?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                You can get in touch with us by simply filling straightforward registration form and we will get back to you as soon as possible. You can also call or email us if you have inquiries and queries.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    Do you also create logos, banners, and graphics for websites and social media?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                Yes, we offer complete website development package including domain, hosting, SSL, logos, banner, content writing, digital feedback, SEO, digital marketing, and other services.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    What are your premium modules?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                You can add our premium modules such as team management, project module, company awards, partners module, appointment solution, expertise section and much more just by paying s mall amount according to your requirements.</p>
			</details>

            <details className='w-full bg-white [&_svg]:open:-rotate-180 rounded-md border-2 border-[#DBDBDB] mb-2'>
				<summary className='bg-[#ffbb00]/20 list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
					<p className='font-outfit font-bold text-lg'>
                    Do you provide features such as Raise Invoice and Payment Gateways Integration?
					</p>
					<div>
						<svg
							className='rotate-0 transform text-black transition-all duration-300'
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							fill='currentColor'>
							<path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
						</svg>
					</div>
				</summary>
				<p className='xl:px-8 xl:py-5 p-3 font-outfit'>
                Yes, we offer a cutting-edge solution for creating invoices and receiving payments. With our system, you can easily create invoices, send them to your clients, and get paid without any hassle.</p>
			</details>
		</main>
	);
};
export default Questions;
