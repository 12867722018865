import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { FeedDetail } from "../Components/Blogs/FeedDetail";

import { newsService } from "../services/news.service";
import { Subscription } from "rxjs";
import { Loader } from "../Components/Reuseable/loader";


export const NewsDetail = () => {

	const {slug} = useParams();
	const [isLoading, setIsloading] = useState(true);
	const [data, setData] = useState({
		newsDetail: null,
		similarNewsFeeds: []
	})

	useEffect(() => {
		let subscription = new Subscription()
		if(slug) {
			setIsloading(true);
			subscription = newsService.getDetails(slug).subscribe({
				next: ([newsDetail, similarNewsFeeds]) => {
					setIsloading(false);
					setData({
						newsDetail,
                        similarNewsFeeds
                    });
				},
				error: (err) => {
					console.log(err);
				}
			})
		}

		return () => {
			if(subscription && !subscription.closed) {
				subscription.unsubscribe();
				subscription = null;
			}
		}
	}, [slug])

	return (
		<>
			{isLoading ? 
				<div className="h-[50vh] mt-10 w-full flex justify-center items-center">
					<Loader />
				</div>
				:
				<FeedDetail
					route="newsEvents"
					pageName="NEWS & EVENTS"
					data={{feedDetail: data.newsDetail, similarFeeds: data.similarNewsFeeds}} 
				/> 
			}
		</>
	);
};

