import * as yup from 'yup';

export const singUpSchema = yup.object({
    fullName: yup.string().required('First Name is required'),
    Gender: yup.string().required('Gender is required'),
    DateOfBirth: yup.string().required('Date Of Birth is required'),
    Company: yup.string().required('Company is required'),
    Position: yup.string().required('Position is required'),
    Experience: yup.string().required('Experience is required'),
});

