import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const Feed = ({blog, route, switchColor = false}) => {

	const navigate = useNavigate();
	const {ImagePath, CreationDate, EstimatedReadingMinutes, Title, Description, Slug} = blog;

	const month = useMemo(() => blog.Month ? blog.Month.toUpperCase() : '', [blog.Month]);
	const day = useMemo(() => blog.Day ? blog.Day : '', [blog.Day]);

	return (
		<div className='w-full group flex flex-col gap-3 rounded-xl p-4'>
			<div className='h-52 w-full rounded-md overflow-hidden relative'>
				<img
					className="w-full h-full rounded-md object-cover object-center transition-all group-hover:scale-110"
					src={ImagePath}
					alt={Title}
				/>
				{month &&  day ? 
				<div className="absolute top-4 right-4 bg-gkYellow font-outfit text-white flex flex-col items-center rounded-xl p-2 px-3">
					<p className="text-lg">{month}</p>
					<p className="text-xl font-bold -mt-1">{day}</p>
				</div> : null}
			</div>
			<div className='w-full flex flex-row items-center gap-4'>
				<div className='flex items-center'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						height='1em'
						viewBox='0 0 448 512'
						fill='currentColor'
						className={`mr-2 ${switchColor ? 'text-black' : 'text-gkYellow'}`}>
						<path d='M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z' />
					</svg>
					<p className='font-outfit text-sm'>{CreationDate}</p>
				</div>
				<div className='flex items-center'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						height='1em'
						viewBox='0 0 512 512'
						fill='currentColor'
						className={`mr-2 ${switchColor ? 'text-black' : 'text-gkYellow'}`}>
						<path d='M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z' />
					</svg>
					<p className='font-outfit text-sm'>{EstimatedReadingMinutes} Min Read</p>
				</div>
			</div>
			<h2 className='font-outfit font-bold text-sm xl:text-lg leading-tight line-clamp-1'>
				{Title}
			</h2>
			<p className='line-clamp-2 text-sm font-outfit'  dangerouslySetInnerHTML={{ __html: Description }} />
			<p onClick={() => navigate(`/${route}/${Slug}`)} className={`font-outfit text-sm cursor-pointer ${switchColor ? 'text-black' : 'text-gkYellow'}`}>
				Read More
			</p>
		</div>
	);
};