

import {
  set_IconFocus,
  set_POPUP,
  VERIFYPHONEOTP,
  VERIFYEMAILOTP,
  BECOMESELLER,
  CLEARFORM2,
  CLEARFORM,
  set_SUCCESSPOPUP,
  BECOMESELLERERR,
  BECOMESELLERREQ,
  VERIFYPHONE,
  RESET_OTP,
  VERIFYEMAIL,
  
} from '../Actions/actions';


const initialState = {
  HomeIcon: 'Technomitees',
  ShowSellerPopup:false,
  ShowSuccessPopup:false,
  phonenumberverified: false,
  emailverified: false,
  sellerformrequested: false,
  ErrrPopUp:false,
  phoneotpsent: false,
  emailotpsent: false,
  websitelink:"",
  successmessage: ""
};

function userReducer(state = initialState, action) {
  switch (action.type) {
  
    case VERIFYEMAIL:
      return {
        ...state,
        emailotpsent: action.enableotp,
     
      };

    case RESET_OTP:
      return {
        ...state,
        phoneotpsent: action.payload,
        emailotpsent: action.payload,
      };
    
    case VERIFYPHONE:
      return {
        ...state,
        phoneotpsent: action.enableotp,
     
      };

    case set_SUCCESSPOPUP:
      return {
        ...state,
        ShowSuccessPopup: action.payload,
     
      };

    case CLEARFORM2:
      return {
        ...state,
        sellerformrequested: action.payload,
     
      };

    case CLEARFORM:
      return {
        ...state,
        phonenumberverified: action.payload,
        emailverified: action.payload,
        sellerformrequested: action.payload,
     
      };

    case BECOMESELLER:
      return {
        ...state,
        sellerformrequested: action.payload,
        ShowSuccessPopup: action.payload,
        websitelink: action.api,
        successmessage: action.message
      };

      case BECOMESELLERERR:
        return {
          ...state,
         
          ErrrPopUp: action.payload,
        };

        case BECOMESELLERREQ:
          return {
            ...state,
           
            ErrrPopUp:false,
          };
        
      
    
  
    case VERIFYEMAILOTP:
      return {
        ...state,
        emailverified: action.payload,
     
      };
    case VERIFYPHONEOTP:
      return {
        ...state,
        phonenumberverified: action.payload,
     
      };

    case set_POPUP:
      return {
        ...state,
        ShowSellerPopup: action.payload,
     
      };

    case set_IconFocus:
      return {
        ...state,
        HomeIcon: action.home,
     
      };
 
    default:
      return state;
  }
}

export default userReducer;
