import axios from "axios";
import { catchError, finalize, from, map, of } from "rxjs";
import { environment } from "../environment";

class FooterService {

    isLoading = true;
    baseUrl = environment.baseUrl;

    getFooterData(endPoint, params = '', baseUrl = this.baseUrl) {
        this.isLoading = true;
        const path =  `${baseUrl}/${endPoint}${params ? `?${params}` : ''}`;
        return from(axios.get(path))
        .pipe(
            map(res => res.data.Result),
            catchError(() => of(null)),
            finalize(() => this.isLoading = false)
        );
    }
}

export const footerService = new FooterService();