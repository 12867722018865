import axios from "axios";
import { catchError, finalize, forkJoin, from, map, of } from "rxjs";
import { environment } from "../environment";

class BlogService {

    isLoading = true;
    baseUrl = environment.baseUrl;

    getAllBlogs(endPoint, parms = '', payload, baseUrl = this.baseUrl) {
        this.isLoading = true;
        const path =  `${baseUrl}/${endPoint}?${parms}`;
        return from(axios.post(path, payload))
        .pipe(
            map(res => res.data.Result),
            catchError(() => of([])),
            finalize(() => this.isLoading = false)
        );
    }

    getDetails(slug) {
        this.isLoading = true;
        return forkJoin([this.getBlogDetail(slug), this.getSimilarBlogs(slug)])
        .pipe(
            catchError(() => of([null, []])),
            finalize(() => this.isLoading = false)
        )
    }

    getBlogDetail(slug) {
        const path = `${this.baseUrl}/api/Customer/Blog/Blogs/${slug}`
        return from(axios.get(path)).pipe(map(res => {
            console.log(res.data.Result)
            return res.data.Result;
        }));
    }
    
    getSimilarBlogs(slug) {
        const path = `${this.baseUrl}/api/Customer/Blog/Blogs/${slug}/RelatedBlogs`
        return from(axios.get(path)).pipe(map(res => {
            console.log(res.data.Result)
            return res.data.Result;
        }));
    }
}

export const blogService = new BlogService();