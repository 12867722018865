/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Breadcrumbs from "../../Components/Reuseable/Breadcrumbs";
import Seperator from "../Seperator";
import appstore from "../../Resources/appstoreWebbeecart.png";
import playstore from "../../Resources/playstoreWebbeecart.png";

import webbeecartHero from "../../Resources/webbeecarthero.png";
import becomesellerHeroImg from "../../Resources/BecomeSeller-hero-img.png";
import b2bHeroImg from "../../Resources/b2b-hero.png";

// import Bookdemocard from "../Bookdemocardnew";
// import Scrolltoform from "../Scrolltoform";

const webbeecartMainBanner = (props) => {
  return (
    <div className="bg-[#FCF2E1] w-full mt-40">
      <div className="flex flex-col-reverse lg:flex-row mt-24 lg:w-[90%] xl:w-[80%] py-10 xl:py-0 mx-auto">
        <div className="w-full items-center lg:items-start lg:w-[50%] flex flex-col xl:ml-4 pb-5 lg:pb-12">
          <div className="pt-5 lg:pt-12">
            <Breadcrumbs PageName={props.PathRoute} />
          </div>
          <p className="font-outfit font-extrabold text-center lg:text-left text-[32px] text-[#000000] pt-1">
            {props.AboutWhat}
            <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
              {props.AboutWhat3}
            </span>
          </p>
          <p className="font-outfit font-extrabold text-center lg:text-left text-3xl md:text-4xl xl:text-5xl text-[#000000] pb-1">
            <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
              {props.AboutWhat1}
            </span>
            {props.AboutWhat2}
          </p>
          <Seperator />
          <div className="pt-1 lg:w-[85%]">
            <p className="font-outfit text-center lg:text-left px-5 lg:px-0 lg:w-[80%] xl:w-full text-[15px] md:text-[17px] text-[#000000] font-bold">
              {props.Desc} <br />
              {props.Desc2} <br />
              {props.Desc3}
            </p>
          </div>
          <div className="">
          <p className="font-outfit font-extrabold text-center lg:text-left text-[32px] text-[#000000] pt-1">
            Scan QR Code:
          </p>
          <div className="flex flex-col lg:flex-row justify-between items-center w-[90%] mx-auto lg:w-full mt-4 gap-4 lg:gap-0 lg:mt-2">
            <div className="w-full flex flex-row bg-[#FCF2E1] p-2 rounded-md gap-2">
              <div className="w-[50%]">
                <img
                  src={appstore}
                  className="w-full h-full object-contain"
                  alt="QR for AppStore"
                />
              </div>
              <div className="flex flex-col items-start justify-center text-[20px] md:text-[34px] lg:text-[20px] font-outfit  leading-tight w-full 2xl:px-2">
                <p>Download for AppStore</p>
              </div>
            </div>
            <div className="w-full flex flex-row bg-[#FCF2E1] p-2 rounded-md gap-2">
              <div className="w-[50%]">
                <img
                  src={playstore}
                  className="w-full h-full object-contain"
                  alt="QR for PlayStore"
                />
              </div>
              <div className="flex flex-col items-start justify-center text-[20px] md:text-[34px] lg:text-[20px] font-outfit  leading-tight w-full 2xl:px-2">
                <p>Download for Playstore</p>
              </div>
            </div>
          </div>
          </div>
          {/* <button
					type='button'
					className='bg-[#F2B307] py-3 text-sm rounded-md mt-4 text-white w-[65%] md:w-[50%] xl:w-[30%] font-manrope'
					onClick={() => {
						dispatch(setShowpopUp(true));
					}}
				>
					Book Your Demo Now
				</button> */}
          {/* {props?.showbuttun !== false && !props.pmHideButton && (
            // <Bookdemocard background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"}/>
            <Scrolltoform
              title={props.PathRoute}
              click={props.clicked}
              background={"bg-[#000000] text-white"}
              background2={"bg-[#FFBB00]"}
            />
          )} */}

          {/* {props.pmHideButton == true ? props.showbuttun == false : props.showbuttun} */}

          {/* {props?.showbuttun === false && (
            // <Bookdemocard background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"}/>
            // <Bookdemocard  background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"}/>
            <div
              className={`bg-[#FFBB00] text-white pb-[3px] cursor-pointer rounded-lg sm:rounded-md lg:rounded-lg w-max`}
            >
              <div className="bg-black rounded-lg sm:rounded-md lg:rounded-lg w-max py-[13px] font-outfit font-bold text-[16px] lg:text-[18px] xl:text-sm px-5 xl:px-6" >
                <a
                href="http://www.webbeecart.com"
                target="_blank"
                  className={``} 
                >
                  Demo Store
                </a>
              </div>
            </div>
            // <Bookdemocardnew
            //   background={"bg-[#000000] text-white"}
            //   background2={"bg-[#FFFFFF]"}
            // />
          )} */}
        </div>
        <div className="w-full lg:w-[50%] flex justify-center xl:justify-end mt-14 xl:mr-4">
          <img
            src={
              props.typeof === "BecomeSeller" && props.pmHideButton === false
                ? becomesellerHeroImg
                : props.typeof === "BecomeSeller" && props.pmHideButton === true
                ? b2bHeroImg
                : webbeecartHero
            }
            className="w-[90%] mx-auto lg:w-full h-full object-contain"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default webbeecartMainBanner;
