



import React, { useState, useEffect } from 'react'
import Logo from '../Resources/Logo.png'
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";
import { Route, Routes, Link } from 'react-router-dom';
import Featurecard2 from './Featurecard2'
import './index.css'

const Beehivecard = ({ item }) => {
  let Features2 = [
    {
      label: 'We build secure & reliable website',

    },
    {
      label: 'Your website is ready in 48 hours',

    },
    {
      label: 'Quick & easy to use',

    },
    {
      label: 'No tech worries',

    },
    {
      label: 'Affordable pricing',

    },

  ]
  let Features3 = [

    {
      label: 'User-Friendly Backend Interface ',

    },
    {
      label: 'Mobile responsive',

    },
    {
      label: 'Customizable',

    },
    {
      label: 'SEO & Digital marketing friendly',

    },
    {
      label: '24/7 customer support',

    },
  ]

  return (

    <div className='w-full overflow-x-scroll scrollbar-none pt-8 flex flex-col lg:items-center' data-aos='zoom-in' data-aos-offset='100' data-aos-deleay='200' data-aos-once="true">
      <div className='flex flex-row items-center pr-[114px]  '>
        {Features2?.map((item, index) => {
          return (
            <Featurecard2 key={index} item={item} />
          )
        })}
      </div>

      <div className=' flex flex-row items-center  pl-[114px] sm:pl-[63px] md:pl-[125px] lg:pl-[135px] -mt-[85px] sm:-mt-[75px] md:-mt-[110px]'>
        {Features3?.map((item, index) => {
          return (
            <Featurecard2 key={index} item={item} />
          )
        })}
      </div>
    </div>
  )
}

export default Beehivecard



