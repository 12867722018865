

// import RNFetchBlob from 'rn-fetch-blob';

import {useSelector, useDispatch} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { environment } from '../environment';
// export const GetBlogsHome = 'GetBlogsHome';
export const set_IconFocus = 'set_IconFocus';
export const BECOMESELLER = 'BECOMESELLER';
export const BECOMESELLERERR = 'BECOMESELLERERR';
export const BECOMESELLERREQ = 'BECOMESELLERREQ';
export const VERIFYPHONE = 'VERIFYPHONE';
export const VERIFYPHONEOTP = 'VERIFYPHONEOTP';
export const VERIFYEMAILOTP = 'VERIFYEMAILOTP';
export const VERIFYEMAIL = 'VERIFYEMAIL';
export const CLEARFORM = 'CLEARFORM';
export const CLEARFORM2 = 'CLEARFORM2';
export const set_POPUP = 'set_POPUP';
export const set_SUCCESSPOPUP = 'set_SUCCESSPOPUP';
export const RESET_OTP = 'RESET_OTP';


// const API_URl = 'https://api.webbeesite.com/api/';
const API_URl = `${environment.baseUrl}/api/`;


const header1 = {
  'Content-Type': 'application/x-www-form-urlencoded',
};


export const resetotpstate = () => {
  try {
    return async dispatch => {

        dispatch({
          type: RESET_OTP,
          payload: false,
       
        });
      
    };
  } catch (error) {
    console.log(error);
  }
};

export const clearformrequest = () => {
  try {
    return async dispatch => {

        dispatch({
          type: CLEARFORM,
          payload: false,
       
        });
      
    };
  } catch (error) {
    console.log(error);
  }
};

export const clearformrequest2 = () => {
  try {
    return async dispatch => {

        dispatch({
          type: CLEARFORM2,
          payload: false,
       
        });
      
    };
  } catch (error) {
    console.log(error);
  }
};

export const seticonfocus = icon => {
  try {
    return async dispatch => {

        dispatch({
          type: set_IconFocus,
          home: 'true',
       
        });
      
    };
  } catch (error) {
    console.log(error);
  }
};

export const setShowpopUp = (bool) => {
  try {
    return async dispatch => {

        dispatch({
          type: set_POPUP,
          payload: bool,
       
        });
      
    };
  } catch (error) {
    console.log(error);
  }
};

export const setSuccesspopUp = (bool) => {
  try {
    return async dispatch => {

        dispatch({
          type: set_SUCCESSPOPUP,
          payload: bool,
       
        });
      
    };
  } catch (error) {
    console.log(error);
  }
};

export const setBecomeASeller = (nameAsPerTradeLicense,firstName, email, requirePhoneNumberConfirmation, password, phoneNumber) => {
  try {
    return async dispatch => {
      dispatch({
        type: BECOMESELLERREQ,
     
      });
    
      const result = await fetch(API_URl + 'VendorAccount/Register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          
            nameAsPerTradeLicense: nameAsPerTradeLicense,
            firstName:firstName,
            email: email,
            requirePhoneNumberConfirmation: requirePhoneNumberConfirmation,
            password: password,
            phoneNumber: phoneNumber
            
        }),
      });

      const json = await result.json();

      console.log('setBecomeASeller' + JSON.stringify(json));

    
      if (json.Status == 'Success') {
        toast.success("Seller request submitted successfully")
        dispatch({
          type: BECOMESELLER,
          payload:true,
          api: "https://portal.demowbs.com/vendor",
          message: "Your Seller Account has been created successfully"
        });
       
      }else{
        dispatch({
          type: BECOMESELLERERR,
          payload:true,
        });
        toast.error(json.Message)
      }
    };
  } catch (error) {
    console.log(error);
  }
};

export const setCreateWebsite = (nameAsPerTradeLicense,firstName, email, requirePhoneNumberConfirmation, password, phoneNumber) => {
  try {
    return async dispatch => {


try{
      
      dispatch({
        type: BECOMESELLERREQ,
     
      });
    
      const result = await fetch(API_URl + 'VendorAccount/Client/Register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          
            nameAsPerTradeLicense: nameAsPerTradeLicense,
            firstName:firstName,
            email: email,
            requirePhoneNumberConfirmation: requirePhoneNumberConfirmation,
            password: password,
            phoneNumber: phoneNumber
            
        }),
      });

      const json = await result.json();

      console.log('setBecomeASeller' + JSON.stringify(json));

    
      if (json.Status == 'Success') {
        toast.success("Seller request submitted successfully")
        dispatch({
          type: BECOMESELLER,
          payload:true,
          api: "https://portal.demowbs.com/customerclient",
          message: "Your Client Account has been created successfully"
        });
     
      }else{
        dispatch({
          type: BECOMESELLERERR,
          payload:true,
        });
        toast.error(json.Message)
      }
    }catch(error){
      dispatch({
        type: BECOMESELLERERR,
        payload:true,
      });
      toast.error(error)
    }
    };

 
  } catch (error) {
    console.log(error);
    toast.error(error)
  }
};
export const VerifySellerPhonenumber = (phoneNumber) => {
  try {
    return async dispatch => {
    console.log("yo")
      const result = await fetch(API_URl + `VendorAccount/SendOTPNumber/${phoneNumber}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
    
      })

      const json = await result.json();

      console.log('VerifySellerPhonenumber' + JSON.stringify(json));

    
      if (json.Status == 'Success') {
        toast.success("OTP generated successfully")
        dispatch({
          type: VERIFYPHONE,
          payload: json.Message,
          enableotp: true
        });
      }else{
        toast.error(json.Message)
        dispatch({
          type: VERIFYPHONE,
          payload: json.Message,
          enableotp: false
        });
      }
    };
  } catch (error) {
    toast.error("There was an error while generating your OTP. Please try again later")
    console.log(error);
  }
};

export const VerifySellerPhonenumberOTP = (phoneNumber, otp) => {
  try {
    return async dispatch => {
    
      const result = await fetch(API_URl + `VendorAccount/VerifyOTPForVendor/${otp}/${phoneNumber}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
    
      });

      const json = await result.json();

      console.log('VerifySellerPhonenumberOTP' + JSON.stringify(json));

    
      if (json.Status == 'Success') {
        toast.success(json.Message)
        dispatch({
          type: VERIFYPHONEOTP,
          payload: true,
        });
      }else{
        toast.error(json.Message)
      }
    };
  } catch (error) {
    toast.error("There was an error while verifying your OTP. Please try again later")
    console.log(error);
  }
};



export const VerifySellerEmail = (email) => {
  try {
    return async dispatch => {
      console.log('Verifying');
      const result = await fetch(API_URl + `VendorAccount/SendOTPEmail/${email}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
    
      });

      const json = await result.json();

      console.log('VerifySellerEmail' + JSON.stringify(json));

    
      if (json.Status == 'Success') {
        toast.success(json.Message)
        dispatch({
          type: VERIFYEMAIL,
          payload: json,
          enableotp: true

        });
      }else{
        toast.error(json.Message)
        dispatch({
          type: VERIFYEMAIL,
          payload: json,
          enableotp: false
        });
      }
    };
  } catch (error) {
    console.log(error);
  }
};


export const VerifySellerEmailOTP = (email, otp) => {
  try {
    return async dispatch => {
  
      
      const result = await fetch(API_URl + `VendorAccount/ConfirmEmail/${otp}/${email}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
    
      });

      const json = await result.json();

      console.log('VerifySellerEmailOTP' + email + otp + JSON.stringify(json));

    
      if (json.Status == 'Success') {
        toast.success(json.Message)
        dispatch({
          type: VERIFYEMAILOTP,
          payload: true,
        });
      }else{
        toast.error(json.Message)
      }
    };
  } catch (error) {
    toast.error("There was an error while verifying your OTP. Please try again later")
    console.log(error);
  }
};