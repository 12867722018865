import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Feed } from "../Components/Blogs/feed";
import { Pagination } from "../Components/Reuseable/pagination";
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs";

import { newsService } from "../services/news.service";
import { Loader } from "../Components/Reuseable/loader";


export const NewsEvents = () => {

	const [isLaoding, setIsloading] = useState(true);
    const [data, setData] = useState({
        news: [],
        totalCount: 0
    })
	const [queryFilters, setQueryFilters] = useState({
		currentPage: 1,
		pageOffSet: 12,
	});

    const newsContent = useMemo(() => {
        return(
            <>
            {isLaoding ? <div className="h-[50vh] w-full flex justify-center items-center"><Loader /></div> :
			data.news && data.news.length ? 
					<div className="w-full flex flex-row flex-wrap gap-2">
						{data.news.map((item) => 
							<div key={item.Slug} className="w-full md:w-[49%] xl:w-[32%]">
								<Feed blog={item} route="newsEvents" />
							</div>
						)}	
				</div> 
				: <div className="w-full h-[40vh] mt-10 bg-gray-100 flex flex-col justify-center items-center rounded-xl">
					<p className="text-gray-500 font-outfit font-bold text-xl">NO NEWS AND EVENTS</p>
					<p className="text-gray-400 font-outfit">There are no news and events available right now</p>
				</div>}
            </>
        )
    }, [data.news, isLaoding])

    const handlePageChange = useCallback((page) => {
		setQueryFilters((obj) => ({
			...obj,
            currentPage: page
		}));
	}, [])

	useEffect(() => {
		setIsloading(true);
        const payload = { 
            search: "", 
            pageNumber: queryFilters.currentPage, 
            pageSize: queryFilters.pageOffSet
        }
		const subscription = newsService.getAllNews('api/Customer/NewsandEvents/NewsEvent', 'ForHomePage=false', payload).subscribe({
			next: (res) => {
				setIsloading(false);
                setData({
                    news: res.NewsAndEvents || [],
                    totalCount: res.TotalNews
                })
			},
			error: (err) => {
				console.log(err);
			}
		});

		return () => {
			if(subscription && !subscription.closed){
				subscription.unsubscribe();
			}
		}
	}, [queryFilters])

	return (
		<main className='w-full py-10 xl:py-20 bg-[#FFFFFF]'>
			<div className='w-[90%] xl:w-[80%] mx-auto flex flex-col justify-between items-start'>
				<div className='pt-28'>
					<Breadcrumbs PageName={"NEWS AND EVENTS"} />
				</div>
				<p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl text-left mt-3 xl:w-[60%]'>
                    <span>News And<br/></span>
					<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_25%]'>
						Events
					</span>
				</p>
				<div className='h-1 xl:h-[2px] bg-[#F2B307] w-[12%] sm:w-[10%] lg:w-[5%] my-4 mx-0'></div>
				<p className='font-outfit font-semibold text-black text-justify xl:w-[60%] mb-4'>
					Stay up to date with the latest happenings and events in our
					company. We regularly update this section with exciting news and
					upcoming events.
				</p>
				{newsContent}
			</div>
			{data.totalCount > 0 ? 
                <div className="w-full flex justify-center mt-10">
                    <Pagination 
                        count={data.totalCount} 
                        pageOffset={queryFilters.pageOffSet}
                        currentPage={queryFilters.currentPage}
                        onChange={handlePageChange}
                    />
                </div> 
            : null}
		</main>
	);
};