import React from 'react'

export const Loader = ({size = 12, color = '#F2B307', className = ''}) => {

  const s = size / 4;

  return (
    <div className={className}>
      <div className={`animate-spin rounded-full border-4 border-t-4 border-gray-200`} style={{borderTopColor: color, width: `${s}rem`, height: `${s}rem`}}></div>
    </div>
  )
}
