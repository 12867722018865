import React from "react";
import { Route, Routes, Link } from "react-router-dom";

import "./index.css";

const Redirectb2b = ({ background, background2 }) => {

	return (
		<Link to={"/CreateWebsite"}>
            <div className={`${background2} pb-[3px] cursor-pointer rounded-lg sm:rounded-md lg:rounded-lg w-max`}>
			    <div className={`${background} rounded-lg sm:rounded-md lg:rounded-lg w-max py-[13px] font-outfit font-bold text-           [16px] lg:text-[18px] xl:text-sm px-5 xl:px-6`}>
				    Start Building Your Website Now
			    </div>
		    </div>
        </Link>
	);
};

export default Redirectb2b;
