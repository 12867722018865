import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Feed } from "../Components/Blogs/feed";
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs";
import { Pagination } from "../Components/Reuseable/pagination";

import { blogService } from "../services/blog.service";
import { Loader } from "../Components/Reuseable/loader";


export const Blogs = () => {

	const [isLoading, setIsloading] = useState(true);
    const [data, setData] = useState({
        blogs: [],
        totalCount: 0
    })
	const [queryFilters, setQueryFilters] = useState({
		currentPage: 1,
		pageOffSet: 12,
	});

	const blogsContent = useMemo(() => {
        return(
            <>
            {isLoading ? <div className="h-[50vh] w-full flex justify-center items-center"><Loader size={12} color="#F2B307" /></div> :
			data.blogs && data.blogs.length ? 
					<div className="w-full flex flex-row flex-wrap gap-2">
						{data.blogs.map((item) => 
							<div key={item.Slug} className="w-full md:w-[49%] xl:w-[32%]">
								<Feed blog={item} route="blogs" />
							</div>
						)}	
				</div> 
				: <div className="w-full h-[40vh] mt-10 bg-gray-100 flex flex-col justify-center items-center rounded-xl">
					<p className="text-gray-500 font-outfit font-bold text-xl">No Blogs</p>
					<p className="text-gray-400 font-outfit">There are no blogs available right now</p>
				</div>
			}
            </>
        )
    }, [data.blogs, isLoading])

	const handlePageChange = useCallback((page) => {
		setQueryFilters((obj) => ({
			...obj,
            currentPage: page
		}));
	}, [])

	useEffect(() => {
		const payload = { 
            search: "", 
            pageNumber: queryFilters.currentPage, 
            pageSize: queryFilters.pageOffSet
        }
		setIsloading(true);
		const subscription = blogService.getAllBlogs('api/Customer/Blog/Blogs', 'ForHomePage=false', payload).subscribe({
			next: (res) => {
				setData({
					blogs: res.Blogs || [],
					totalCount: res.TotalBlogs
				});
				setIsloading(false);
			},
			error: (err) => {
				console.log(err);
			}
		});

		return () => {
			if(subscription && !subscription.closed){
				subscription.unsubscribe();
			}
		}
	}, [queryFilters])

	return (
		<main className='w-full py-10 xl:py-20 bg-[#FFFFFF]'>
			<div className='w-[90%] xl:w-[80%] mx-auto flex flex-col justify-between items-start'>
				<div className='pt-28'>
					<Breadcrumbs PageName={"BLOGS"} />
				</div>
				<p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl text-left mt-3 xl:w-[60%]'>
					<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_25%]'>
						Blogs
					</span>
				</p>
				<div className='h-1 xl:h-[2px] bg-[#F2B307] w-[12%] sm:w-[10%] lg:w-[5%] my-4 mx-0' />
				<p className='font-outfit font-semibold text-black text-justify xl:w-[60%] mb-4'>
					Dive into the world of web development through our insightful blogs. From industry trends and design tips to useful DIYs, our blog section is your source for staying updated and informed.
				</p>
				{blogsContent}
			</div>
			{data.totalCount > 0 ? 
			<div className="w-full flex justify-center mt-10">
				<Pagination 
					count={data.totalCount} 
					currentPage={queryFilters.currentPage}
					pageOffset={queryFilters.pageOffSet}
					onChange={handlePageChange}
				/>
			</div> : null}
		</main>
	);
};