import React, { useState, useEffect } from "react";

import { CgArrowLongRight, CgArrowLongLeft } from "react-icons/cg";

import Featurecard from "../Components/Featurecard";

import Beehivecontainer from "../Components/Beehivecontainer";

import homeimage from "../Resources/homeimage.png";
import text1home from "../Resources/text1home.gif";
import text2home from "../Resources/text2home.gif";
import text3home from "../Resources/text3home.gif";
import text4home from "../Resources/cp.gif";

import text5home from "../Resources/text5home.gif";
import feature1 from "../Resources/feature1.png";
import feature2 from "../Resources/feature2.png";
import feature3 from "../Resources/feature3.png";
import feature4 from "../Resources/feature4.png";
import feature5 from "../Resources/feature5.png";
import feature6 from "../Resources/feature6.png";
import feature7 from "../Resources/feature7.png";
import feature8 from "../Resources/feature8.png";
import feature9 from "../Resources/feature9.png";
import feature10 from "../Resources/feature10.png";
import feature11 from "../Resources/feature11.png";
import feature12 from "../Resources/feature12.png";
import feature13 from "../Resources/feature13.png";
import feature14 from "../Resources/feature14.png";
import feature15 from "../Resources/feature15.png";
import feature16 from "../Resources/feature16.png";
import feature17 from "../Resources/feature17.png";
import feature18 from "../Resources/feature18.png";
import feature19 from "../Resources/feature19.png";
import feature20 from "../Resources/feature20.png";
import feature23 from "../Resources/feature23.png";
import feature24 from "../Resources/feature24.png";
import feature25 from "../Resources/feature25.png";
import profileimage1 from "../Resources/profileimage1.png";
import profileimage2 from "../Resources/profileimage2.png";
import profileimage3 from "../Resources/image3.png";
import Bookdemocard from "../Components/Bookdemocard";
import Redirectb2b from "../Components/redirectb2b";
import { FeedList } from "../Components/Blogs/feed-list";
import { blogService } from "../services/blog.service";
import { forkJoin, map } from "rxjs";
import { newsService } from "../services/news.service";

const Features = [
  {
    label: "Domain Name",
    img: feature1,
    number: "01",
  },
  {
    label: "Web Hosting",
    img: feature2,
    number: "02",
  },
  {
    label: "Free SSL",
    img: feature3,
    number: "03",
  },
  {
    label: "Corporate Emails",
    img: feature4,
    number: "04",
  },
  {
    label: "Logo Creation",
    img: feature5,
    number: "05",
  },
  {
    label: "Website Banners Design",
    img: feature6,
    number: "06",
  },
  {
    label: "Content Writing",
    img: feature7,
    number: "07",
  },
  {
    label: "Service & Product Module",
    img: feature8,
    number: "08",
  },
  {
    label: "Customer Testimonials",
    img: feature9,
    number: "09",
  },
  {
    label: "Blogs, News & Events Section",
    img: feature10,
    number: "10",
  },
  {
    label: "Subscriber`s Management",
    img: feature11,
    number: "11",
  },
  {
    label: "Business Information Integration",
    img: feature12,
    number: "12",
  },
  {
    label: "Digital Feedback",
    img: feature13,
    number: "13",
  },
  {
    label: "Social Media Links",
    img: feature14,
    number: "14",
  },
  {
    label: "Dynamic Contact Us Page",
    img: feature15,
    number: "15",
  },
];
const Features1 = [
  {
    label: "Project Module",
    img: feature16,
    number: "16",
  },
  {
    label: "Team/Work Management",
    img: feature18,
    number: "17",
  },
  {
    label: "Company Awards Module",
    img: feature17,
    number: "18",
  },
  {
    label: "Our Partners Module",
    img: feature19,
    number: "19",
  },
  {
    label: "Expertise Section",
    img: feature24,
    number: "20",
  },
  {
    label: "Appointment Solution",
    img: feature25,
    number: "21",
  },
  {
    label: "Raise Invoice Via Payment Gateway",
    img: feature20,
    number: "22",
  },
  // {
  //   label: "SMS Marketing",
  //   img: feature21,
  //   number: "23",
  // },
  // {
  //   label: "Social Media Marketing",
  //   img: feature22,
  //   number: "24",
  // },
  {
    label: "Dedicated 24/7 Support",
    img: feature23,
    number: "23",
  },
];
const Comments = [
  {
    id: 0,
    comment:
      "WEBBEESITE has become my favorite choice for website development and related services. It all started when I set up a business and needed a website for it. I was worried about all the complexities involved in buying hosting and domain, hiring a Developer, Designer, and whatnot else until I found WEBBEESITE. It is truly a one-stop solution as it provides a user-friendly backend portal that allows everyone, even with no technical/development knowledge to manage their website and customize it however they want. The plus point: all of this comes with a mind-blowing budget-friendly price. RECOMMENDED!!",
    commenter: "Jeff Irby",
    designation: "VP, Sales & Marketing at Drivestream",
    picture: profileimage1,
  },
  {
    id: 1,
    comment:
      "I recently collaborated with WEBBEESITE team to create a new website for my business. They were fantastic to work with, delivering the website on time and on budget. The website is visually stunning and functionally perfect, and the team was more than willing to make any changes I requested. I'm very happy with the final product and would highly recommend WEBBEESITE for any website development needs.",
    commenter: "James Smith",
    designation: "Director Program at Global Solutions",
    picture: profileimage2,
  },
  {
    id: 2,
    comment:
      "WEBBEESITE was a game-changer for me in website development. Building and customizing a website design is incredibly easy with its intuitive drag-and-drop interface. No coding needed. The range of templates and design options make creating a unique website a super easy. It has become my favorite website development company in UAE.  ",
    commenter: "Heather O'Donnell",
    designation: "Sr. Project Manager, HHG Worldwide",
    picture: profileimage3,
  },
];

export const HomeScreen = () => {

  const [data, setData] = useState({
    blogs: [],
    events: []
  });

  const [CommentIndex, setCommentIndex] = useState(0);
  const [ScollDirection, setScollDirection] = useState("right");

  const [isTruncate, setIsTruncate] = useState(true);

  const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        className="mx-auto w-full rounded-2xl"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  useEffect(() => {
		const payload = { 
      search: "", 
      pageNumber: 1, 
      pageSize: 12
    }
		const subscription = forkJoin([
      blogService.getAllBlogs('api/Customer/Blog/Blogs', 'ForHomePage=true', payload),
      newsService.getAllNews('api/Customer/NewsandEvents/NewsEvent', 'ForHomePage=true', payload)
    ]).pipe(map(([blogsRes, newsRes]) => [blogsRes.Blogs, newsRes.NewsAndEvents]))
    .subscribe({
			next: ([blogs, news]) => {
				setData({
					blogs: blogs,
					events: news
				});
			},
			error: (err) => {
				console.log(err);
			}
		});

		return () => {
			if(subscription && !subscription.closed){
				subscription.unsubscribe();
			}
		}
	}, [])

  return (
    <div className="w-full flex flex-col items-center bg-white">
      {/* bg-cover bg-no-repeat bg-main-bg-HomeBackground */}
      <div className=" w-full h-screen flex flex-col items-center justify-end p-4 self-center">
        {/* bg-main-bg-homeimage */}
        <div className="items-center  w-[65%] h-[80%] sm:w-[50%] sm:h-[60%] md:w-[65%] md:top-[10%] lg:w-[50%] lg:top-[20%] top-[0%] sm:top-[25%]  position absolute  z-60 bg-center bg-contain bg-no-repeat flex justify-center ">
          <img
            src={homeimage}
            className="w-[85%] sm:w-[70%] md:w-[90%] lg:w-[100%] xl:w-[70%] position absolute  "
            alt="Website Development Company In UAE"
          />

          <img
            src={text1home}
            className="w-[50%] hover:w-[65%] sm:w-[30%] md:w-[50%] lg:w-[40%] lg:hover:w-[50%] xl:w-[33%] xl:hover:w-[43%] sm:hover:w-[40%] md:hover:w-[60%] mr-[95%] mt-[-20%]  sm:mt-[-30%] hover:opacity-100 opacity-50   position absolute lg:mr-[120%] xl:mr-[100%] xl:mt-[-30%]"
            alt="Not sure about the technology?"
            data-aos="slide-left"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          />
          <img
            src={text2home}
            className="w-[50%] hover:w-[65%] sm:w-[30%] md:w-[50%] lg:w-[40%] lg:hover:w-[50%] xl:w-[33%] xl:hover:w-[43%] sm:hover:w-[40%] md:hover:w-[60%] mr-[95%] mt-[45%] sm:mt-[15%] md:mt-[35%]  hover:opacity-100 opacity-50   position absolute  lg:mr-[120%] xl:mr-[100%] xl:mt-[20%]"
            alt="Worried about the development cost?"
            data-aos="slide-left"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          />
          <img
            src={text3home}
            className="w-[50%] hover:w-[65%] sm:w-[30%] md:w-[50%] lg:w-[40%] lg:hover:w-[50%] xl:w-[33%] xl:hover:w-[43%] sm:hover:w-[40%] md:hover:w-[60%] ml-[95%] mt-[-20%] sm:mt-[-30%] hover:opacity-100 opacity-50  position absolute   lg:ml-[120%] xl:ml-[100%] xl:mt-[-30%]"
            alt="Concerned about website security?"
            data-aos="slide-right"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          />
          <img
            src={text4home}
            className="w-[50%] hover:w-[65%] sm:w-[30%] md:w-[50%] lg:w-[40%] lg:hover:w-[50%] xl:w-[33%] xl:hover:w-[43%] sm:hover:w-[40%] md:hover:w-[60%] ml-[95%] mt-[45%] sm:mt-[15%] md:mt-[35%] hover:opacity-100 opacity-50   position absolute lg:ml-[120%] xl:ml-[100%] xl:mt-[20%]"
            alt="Looking for a custom website design?"
            data-aos="slide-right"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          />
          <img
            src={text5home}
            className="w-[65%] hover:w-[80%] sm:w-[40%] md:w-[75%] lg:w-[55%] lg:hover:w-[70%] xl:w-[45%] xl:hover:w-[58%] sm:hover:w-[50%] md:hover:w-[85%] mt-[-80%] sm:mt-[-60%] md:mt-[-80%] hover:opacity-100 opacity-50 position absolute  xl:mt-[-70%]"
            alt="Having trouble finding a good website developer?"
            data-aos="slide-up"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          />
        </div>
        {/* <img src={homeimage} className="w-[65%] position absolute top-[20%] lg:hidden" alt=""/> */}

        {/* data-aos='zoom-in' data-aos-offset='100' data-aos-deleay='200' data-aos-once="true" */}
        <div className="flex flex-col items-center pb-5 sm:pb-0 md:pb-10 lg:pb-0 z-0 w-full">
          <p className="text-[#000000] text-[26px] mt-[-18rem] xl:mt-0 sm:text-[18px] md:text-[36px] lg:text-[48px] font-outfit font-extrabold w-full text-center">
            Are you looking for a quality website?
          </p>
          <div className="bg-[#FFBB00] w-[7%] h-[2px]"></div>

          <h1 className="text-[#000000] text-[18px] mb-3 sm:text-[10px] md:text-[28px]  lg:text-2xl font-outfit font-medium text-center py-2 sm:py-1 lg:py-2">
            <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_50%]">
              WEBBEESITE
            </span>{" "}
            is the leading website development company in UAE and a solution for all your website development problems. 
          </h1>
          {/* <Seperator /> */}
          {/* <Bookdemocardnew
            background={"bg-[#000000] text-white"}
            background2={"bg-[#FFFFFF]"}
          /> */}

          <Bookdemocard
            background={"bg-[#000000] text-white"}
            background2={"bg-[#FFBB00]"}
          />

          {/* <Bookdemocard background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"} /> */}
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center h-auto bg-[#FEF2E1] px-4 py-8 lg:py-16">
        <p className="text-[#2D2D2D] text-[24px] sm:text-[14px] md:text-[24px] lg:text-[32px] xl:text-4xl font-outfit font-extrabold text-center">
          Get{" "}
          <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%]">
            your website
          </span>{" "}
          with in 72 hours{" "}
        </p>
        <p className="text-[#2D2D2D] text-[18px] sm:text-[10px] md:text-[20px] lg:text-[22px] font-outfit font-semibold text-center py-2">
          which includes the following:
        </p>
        <div className="h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[8%] my-1 mx-auto xl:mx-0">
          {" "}
        </div>
        <div
          className="flex flex-row flex-wrap items-center justify-center pt-4 lg:pt-8 sm:px-16 md:px-14 xl:w-[85%]"
          data-aos="slide-up"
          data-aos-offset="100"
          data-aos-deleay="200"
          data-aos-once="true"
        >
          {Features?.map((item, index) => {
            return <Featurecard key={index} item={item} />;
          })}
        </div>
        {/* <Bookdemocardnew
          background={"bg-[#000000] text-white"}
          background2={"bg-[#FFBB00]"}
        /> */}
        <Redirectb2b
          background={"bg-[#000000] text-white"}
          background2={"bg-[#FFBB00]"}
        />
      </div>
      <div className="w-full flex flex-col items-center justify-center h-auto bg-cover bg-no-repeat bg-main-bg-HomeBackground1 px-4 py-8 md:py-32 xl:py-16 sm:px-16 md:px-14">
        <p className="text-[#000000] text-[24px] sm:text-[14px] md:text-[24px] lg:text-[32px] font-outfit font-extrabold text-center">
          Get{" "}
          <span className="bg-rectangle-white bg-no-repeat bg-bottom bg-[length:100%_45%]">
            {" "}
            premium 
          </span>{" "}
          modules
        </p>
        <p className="text-[#000000] text-[18px] sm:text-[10px] md:text-[20px] lg:text-[24px] xl:text-xl font-outfit font-semibold text-center xl:w-[90%] pt-4">
          Get advanced modules by paying a small amount according to your
          requirements and convenience.
        </p>
        <div className="bg-[#000000] w-[7%] mt-3 h-[2px]"></div>
        <div
          className="flex flex-row flex-wrap items-center justify-center px-4 py-8 xl:w-[85%]"
          data-aos="slide-up"
          data-aos-offset="100"
          data-aos-deleay="200"
          data-aos-once="true"
        >
          {Features1?.map((item, index) => {
            return <Featurecard key={index} item={item} />;
          })}
        </div>
        <Redirectb2b
          background={"bg-[#000000] text-white"}
          background2={"bg-[#FFFFFF]"}
        />
      </div>

      

      <div className="flex flex-col gap-y-4 my-10 xl:my-20">
        <div className="w-[90%] mx-auto">
          <p className="text-[#2D2D2D] text-[24px] sm:text-[14px] md:text-[24px] lg:text-[32px] xl:text-5xl font-outfit font-extrabold text-center">
            What is{" "}
            <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%] uppercase">
              Webbeesite?
            </span>
          </p>
          <p className="text-[#2D2D2D] text-[18px] sm:text-[10px] md:text-[20px] lg:text-[22px] font-outfit font-semibold text-center py-2">
          WEBBEESITE is a user-friendly Content Management System (CMS) that allows users to effortlessly create and manage websites without requiring any developer skills. Sounds interesting right?
            <span className="text-[#f7bb04]"> Enjoy the Video!</span>
          </p>
        </div>
        <div className="relative w-[90%] lg:w-[60%] mx-auto">
          <YoutubeEmbed embedId="XEb5IwFiE8E" />
        </div>
      </div>

      <Beehivecontainer />
      
      {/* News & Events */}
      {data.events.length ? <div className="w-full bg-[#ffbb00] ">
        <FeedList 
          title="News & Events"
          tag="Get advanced modules by paying a small amount according to your requirements and convenience." 
          route="newsEvents"
          feedList={data.events}
        />
      </div> : null}

      {/* Blogs */}
      {data.blogs.length ? <div className="w-full bg-[#FEFBF8]">
        <FeedList 
          title="Blogs" 
          tag="Get advanced modules by paying a small amount according to your requirements and convenience." 
          route="blogs"
          feedList={data.blogs}
        />
      </div> : null}

      {/* bg-cover bg-no-repeat bg-main-bg-HomeBackground2 */}
      <div className="bg-[#FEF2E1] w-full">
      <div className="w-[90%] xl:w-[65%] xl:mx-auto flex flex-col items-center justify-center md:p-16 lg:p-32 px-4 py-8 xl:py-20">
        <p className=" text-[#000000] text-[24px] sm:text-[14px] md:text-[22px] lg:text-[54px] xl:text-4xl font-outfit font-bold text-center w-full">
          <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.2rem] bg-[length:100%_30%]">
            Reviews
          </span>{" "}
        </p>
        <p className="text-[#000000] font-outfit font-semibold pt-3">
          We are the favorite web design company in Dubai for our prestigious customers. See what people say about us:
        </p>
        <div className="bg-[#FFBB00] w-[15%] h-[2px] mt-2"></div>

        <div className="w-full flex flex-row overflow-x-scroll scrollbar-none">
          <div
            key={CommentIndex}
            className="min-w-full max-w-full mt-3"
            data-aos={ScollDirection === "right" ? "slide-left" : "slide-right"}
          >
            <div className="flex flex-row items-center justify-between w-full py-4">
              <div className="flex flex-row gap-4 items-center ">
                <img
                  src={Comments[CommentIndex].picture}
                  className="w-16 sm:w-18 lg:w-28 xl:w-16 rounded-full"
                  alt={Comments[CommentIndex].commenter}
                />
                <div>
                  <p className="text-[#2D2D2D] text-[14px] lg:text-[24px] xl:text-[16px] font-manrope font-bold text-left w-full">
                    {Comments[CommentIndex].commenter}
                  </p>
                  <p className="text-[#2D2D2D]/60 text-[14px] lg:text-[24px] xl:text-[16px] font-manrope text-left w-full">
                    {Comments[CommentIndex].designation}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-end gap-4 mt-8 ">
                <div
                  onClick={() => {
                    if (CommentIndex > 0) {
                      setIsTruncate(true)
                      setScollDirection("left");
                      setCommentIndex(CommentIndex - 1);
                    }
                  }}
                  className=" text-[#2D2D2D] hover:text-[#F2B307] cursor-pointer"
                >
                  <p className=" text-[10px] lg:text-[15px] font-manrope text-left w-full opacity-50">
                    PREV
                  </p>
                  <CgArrowLongLeft className="text-[20px] lg:text-[25px] opacity-50" />
                </div>
                <div
                  onClick={() => {
                    if (CommentIndex < Comments.length - 1) {
                      setIsTruncate(true)
                      setScollDirection("right");
                      setCommentIndex(CommentIndex + 1);
                    }
                  }}
                  className="flex flex-col items-end  text-[#2D2D2D] hover:text-[#F2B307] cursor-pointer"
                >
                  <p className="text-[10px] lg:text-[15px] font-manrope text-left w-full opacity-50">
                    NEXT
                  </p>
                  <CgArrowLongRight className="text-[20px] lg:text-[25px]  opacity-50" />
                </div>
              </div>
            </div>
            <p className={`text-[#000000] text-[16px] sm:text-[10px] md:text-[16px] lg:text-[28px] xl:text-[18px] xl:pt-3 font-outfit font-normal text-justify w-full ${isTruncate ? `line-clamp-4` : ''}`}>
              {Comments[CommentIndex].comment}
            </p><span onClick={() => setIsTruncate(false)} className="cursor-pointer absolute top-[91%] right-0 p-[8px]"></span>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HomeScreen;
