import axios from "axios";
import { catchError, finalize, forkJoin, from, map, of } from "rxjs";
import { environment } from "../environment";

class NewsService {

    isLoading = true;
    baseUrl = environment.baseUrl;
    
    getAllNews(endPoint, parms = '', payload, baseUrl = this.baseUrl) {
        this.isLoading = true;
        const path =  `${baseUrl}/${endPoint}?${parms}`;
        return from(axios.post(path, payload))
        .pipe(
            map(res => res.data.Result),
            catchError(() => of([])),
            finalize(() => this.isLoading = false)
        );
    }

    getDetails(slug) {
        this.isLoading = true;
        return forkJoin([this.getNewsDetail(slug), this.getSimilarNews(slug)])
        .pipe(
            catchError(() => [null, []]),
            finalize(() => this.isLoading = false)
        );
    }

    getNewsDetail(slug) {
        const path = `${this.baseUrl}/api/Customer/NewsandEvents/NewsEvent/${slug}`
        return from(axios.get(path)).pipe(map(res => res.data.Result));
    }

    getSimilarNews(slug) {
        const path = `${this.baseUrl}/api/Customer/NewsandEvents/NewsEvent/${slug}/RelatedNewsEvents`
        return from(axios.get(path)).pipe(map(res => res.data.Result));
    }
}

export const newsService = new NewsService();