import React, { useState } from 'react'
import Breadcrumbs from '../Components/Reuseable/Breadcrumbs'
import { useNavigate } from 'react-router-dom'
import documentIcon from '../Resources/document-icon.png'

const landingPages = [
    {name: 'Home', route: '/'},
    {name: 'About Us', route: '/aboutus'},
    {name: 'Contact Us', route: '/contact'},
    {name: 'Become A Seller', route: '/becomeseller'},
    {name: 'Build Your Website', route: '/createwebsite'},
    {name: 'Blogs', route: '/blogs'},
    {name: 'News And Events', route: '/newsEvents'},
];

const GeneralLinks = [
    {name: 'Privacy Policy', route: '/privacypolicy'},
    {name: 'Terms & Conditions', route: '/termsandconditions'},
    {name: 'Careers', route: '/careers'},
    {name: 'FAQs', route: '/faq'},
];

const SocialLinks = [
    {name: 'Facebook', route: 'https://www.facebook.com/profile.php?id=100095258541731'},
    {name: 'Instagram', route: 'https://instagram.com/webbeesitetechnology?igshid=MzRlODBiNWFlZA=='},
    {name: 'Linkedin', route: 'https://www.linkedin.com/company/webbee-technology'},
    {name: 'Youtube', route: 'https://youtube.com/@WebbeesiteTechnology'},
    {name: 'Whatsapp', route: 'https://api.whatsapp.com/send?phone=971521052477'},
];


export const Sitemap = () => {

  const navigate = useNavigate();
  const [hoverState, setHoverState] = useState({
    name: '',
    isHover: false
  });

  const socialMediaRedirect = (url) => {
      window.open(url, "_blank");
  };


  return (
    <main className='w-full py-10 xl:py-20 bg-[#FFFFFF]'>
        <div className='w-[90%] xl:w-[80%] mx-auto flex flex-col justify-between items-start'>
            <div className='pt-28'>
                <Breadcrumbs PageName={"SITEMAP"} />
            </div>
            <p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl text-left mt-3 xl:w-[60%]'>
                <span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_25%]'>
                    Sitemap
                </span>
            </p>
            <div className='h-1 xl:h-[2px] bg-[#F2B307] w-[12%] sm:w-[10%] lg:w-[5%] my-4 mx-0'></div>
        </div>
        <div className="w-[85%] xl:w-[75%] mx-auto flex flex-row gap-y-8 justify-between flex-wrap mt-10">
            <div className="w-full sm:w-[50%] md:w-[33%] flex flex-col gap-2">
                <div className="flex flex-row gap-4">
                    <img className="w-7" src={documentIcon} alt="" />
                    <div className="font-bold text-lg">Landing Page</div>
                </div>
                <div className="flex flex-col ml-3">
                    {landingPages.map((item, i) => 
                    <div 
                        key={item.name + i} 
                        onClick={() => navigate(item.route)}
                        onMouseEnter={() => setHoverState({name: item.name, isHover: true})} 
                        onMouseLeave={() => setHoverState({name: item.name, isHover: false})}
                        className="flex flex-row gap-2 items-baseline max-w-fit cursor-pointer" 
                    >
                        <div className="relative">
                            <div className={`${item.name === hoverState.name && hoverState.isHover ? 'w-10': 'w-5'} ${i === 0 ? 'h-3' : 'h-6'} border-[#FFBB00] transition-all border-dashed border-l border-b`} />
                            {i === 0 ? <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute top-[-8px] left-[-2px]" /> : null}
                            {i === landingPages.length -1 ? <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute bottom-[-2px] left-[-3px]" /> : null}
                            <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute bottom-[-2px] right-[-9px]" />
                        </div>
                        <div className="mt-1 ml-4 text-sm">{item.name}</div>
                    </div>)}
                </div>
            </div>
            <div className="w-full sm:w-[50%] md:w-[33%] flex flex-col gap-2">
                <div className="flex flex-row gap-4">
                    <img className="w-7" src={documentIcon} alt="" />
                    <div className="font-bold text-lg">General Links</div>
                </div>
                <div className="flex flex-col ml-3">
                    {GeneralLinks.map((item, i) => 
                    <div 
                        key={item.name + i}
                        onClick={() => navigate(item.route)}
                        onMouseEnter={() => setHoverState({name: item.name, isHover: true})} 
                        onMouseLeave={() => setHoverState({name: item.name, isHover: false})}
                        className="flex flex-row gap-2 items-baseline max-w-fit cursor-pointer" 
                    >
                        <div className="relative">
                            <div className={`${item.name === hoverState.name && hoverState.isHover ? 'w-10': 'w-5'} ${i === 0 ? 'h-3' : 'h-6'} border-[#FFBB00] transition-all border-dashed border-l border-b`} />
                            {i === 0 ? <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute top-[-8px] left-[-2px]" /> : null}
                            {i === GeneralLinks.length -1 ? <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute bottom-[-2px] left-[-3px]" /> : null}
                            <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute bottom-[-2px] right-[-9px]" />
                        </div>
                        <div className="mt-1 ml-4 text-sm">{item.name}</div>
                    </div>)}
                </div>
            </div>
            <div className="w-full sm:w-[50%] md:w-[33%] flex flex-col gap-2">
                <div className="flex flex-row gap-4">
                    <img className="w-7" src={documentIcon} alt="" />
                    <div className="font-bold text-lg">Social Media</div>
                </div>
                <div className="flex flex-col ml-3">
                    {SocialLinks.map((item, i) => 
                    <div 
                        key={item.name + i} 
                        className="flex flex-row gap-2 items-baseline max-w-fit cursor-pointer"
                        onClick={() => socialMediaRedirect(item.route)} 
                        onMouseEnter={() => setHoverState({name: item.name, isHover: true})} 
                        onMouseLeave={() => setHoverState({name: '', isHover: false})}
                    >
                        <div className="relative">
                            <div className={`${item.name === hoverState.name && hoverState.isHover ? 'w-10': 'w-5'} ${i === 0 ? 'h-3' : 'h-6'} border-[#FFBB00] transition-all border-dashed border-l border-b`} />
                            {i === 0 ? <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute top-[-8px] left-[-2px]" /> : null}
                            {i === SocialLinks.length -1 ? <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute bottom-[-2px] left-[-3px]" /> : null}
                            <div className="w-[0.4rem] h-[0.4rem] rounded-full border border-[#F2B307] bg-white absolute bottom-[-2px] right-[-9px]" />
                        </div>
                        <div className="mt-1 ml-4 text-sm">{item.name}</div>
                    </div>)}
                </div>
            </div>
        </div>
    </main>
  )
}
