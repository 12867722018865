import React, {  } from 'react'
import './index.css'
import Beehivecard from './Beehivecard'

const Beehivecontainer = () => {

  return (
    <div className='w-full flex flex-col h-auto bg-cover bg-no-repeat bg-main-bg-HomeBackground3 px-4 py-8 md:py-32 xl:py-16'>
      <div className='flex flex-col items-center'>
        <p className='text-[#000000] text-[2rem] font-outfit font-extrabold text-center'><span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_30%]'>Why </span>WEBBESITE is the Top Website Development Company in UAE?</p>
        <div className='xl:w-[90%] mx-auto pt-3'>
          <p className="font-outfit text-center text-2xl">We are the best website development company in UAE offering comprehensive website designing services. With WEBBEESITE, website design in Dubai becomes effortless. We simplify the process of building an attractive, mobile-friendly website without any coding knowledge, providing web design services and web development services tailored to your needs.</p><br />
          <p className="font-outfit text-center text-2xl">A website ensures your long-term success, and with our affordable solutions, you can enjoy all these benefits without spending a fortune. WEBBEESITE reduces website development cost in Dubai for everyone, offering a user-friendly backend interface to customize your site according to your preferences, all at an affordable price.</p>
        </div>
        <div className='h-1 xl:h-[2px] bg-[#F2B307] w-[20%] xl:w-[7%] my-3 mx-auto xl:mx-0'> </div>
      </div>
      <Beehivecard />
      <div className='self-center'>
        {/* <Bookdemocard background={"bg-[#F2B307] text-white"} /> */}
      </div>
    </div>
  )
}

export default Beehivecontainer



