import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { environment } from '../../environment';

export const ConfirmationModal = ({ endpoint, onClose }) => {

  const navigate = useNavigate();

  const handleNavigation = useCallback((isHomepage = true) => {
    onClose(false);
    if(isHomepage) {
        navigate('/')
    } else {
        const url = `${environment.portalUrl}/${endpoint}`;
        window.open(url, '_blank');
    }
  }, [endpoint, navigate, onClose])

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
        document.body.style.overflowY = 'scroll';
    }
  },[])

  return (
    <div className='bg-black/50 w-full h-full fixed inset-0 flex justify-center items-center z-50'>
        <div className='bg-[#EFF1F3] relative py-8 rounded-lg px-5 w-[90%] sm:w-[66%] xl:w-[33%] flex flex-col'>
            <svg viewBox="0 0 20 20" className="absolute top-4 right-4 w-4 cursor-pointer" onClick={() => onClose(false)}>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.414 10l4.293-4.293a1 1 0 0 0-1.414-1.414L10 8.586 5.707 4.293a1 1 0 0 0-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 0 0 1.414 1.414L10 11.414l4.293 4.293a1 1 0 0 0 1.414-1.414L11.414 10z">
                </path>
            </svg>
            <div className="w-full flex flex-col items-center">
                <h1 className="font-outfit font-extrabold uppercase text-gkYellow text-3xl sm:text-2xl">Congratulations!</h1>
                <p className="font-outfit text-base sm:text-lg">Your account has been created successfully.</p>
            </div>
            <div className="w-full flex flex-col sm:flex-row gap-2 justify-between items-center mt-6 relative">
                <div className="w-full sm:w-[49%] transition-all hover:-translate-y-1 hover:scale-105 pb-1 bg-[#F2B307] rounded-lg">
                    <button onClick={() => handleNavigation()} className="w-full whitespace-nowrap text-white flex flex-row justify-center rounded-lg py-3 text-[18px] xl:text-sm font-manrope font-bold transition-all ease-out bg-black cursor-pointer">
                        Return to Homepage
                    </button>
                </div>
                <div className="w-full sm:w-[49%] transition-all hover:-translate-y-1 hover:scale-105 pb-1 bg-[#F2B307] rounded-lg">
                    <button onClick={() => handleNavigation(false)} className="w-full whitespace-nowrap text-white flex flex-row justify-center rounded-lg py-3 text-[18px] xl:text-sm font-manrope font-bold transition-all ease-out bg-black cursor-pointer">
                        Go to Portal
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}
