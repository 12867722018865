import React from "react";

const JobsBox = (props) => {
	return (
		<div className='max-h-[800px] overflow-y-scroll xl:w-[49.5%]'>
			{/* IN LOOP */}
			{props.jobsDatas.map((data, dataIndex)=>{
				return(
					<div className='bg-[#ffbb00]/20 rounded-lg py-4 px-8 mb-4 xl:py-6 cursor-pointer' onClick={()=>{props.checkFunction(data)}}>
						<h1 className='font-extrabold text-xl'> {data.Title}</h1>
						<div>
							<p className='line-clamp-3 xl:text-justify' dangerouslySetInnerHTML={{ __html: data.JobDescription }} />
							<span className='text-[#ffbb00] text-sm cursor-pointer' >
								READ MORE
							</span>
						</div>
						<div className='flex items-center xl:mt-3'>
							<div className='flex justify-center items-center mr-8'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									height='1em'
									viewBox='0 0 448 512'
									className='mr-2 text-[#ffbb00]'
									fill='currentColor'>
									<path d='M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z' />
								</svg>
								<p className=''>{data.CreationDate}</p>
							</div>

							<div className='flex justify-center items-center'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									height='1em'
									fill='currentColor'
									viewBox='0 0 384 512'
									className='mr-2 text-[#ffbb00]'>
									<path d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z' />
								</svg>
								<p className=''>{data.Location}</p>
							</div>
						</div>
					</div>
				)
			})}
		</div>
	);
};

export default JobsBox;
