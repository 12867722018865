import axios from "axios";
import { from, map } from "rxjs";
import { environment } from "../environment";

class BecomeASellerService {

    baseUrl = `${environment.baseUrl}/api`;

    generatePhoneOTP(phoneNumber, baseUrl = this.baseUrl) {
        const path =  `${baseUrl}/VendorAccount/SendOTPNumber/${phoneNumber}`;
        return from(axios.post(path, null)).pipe(map(res => res.data));
    }

    verifyPhoneOTP(phoneNumber, OTP, baseUrl = this.baseUrl) {
        const path =  `${baseUrl}/VendorAccount/VerifyOTPForVendor/${OTP}/${phoneNumber}`;
        return from(axios.put(path, null)).pipe(map(res => res.data));
    }

    generateEmailOTP(email, baseUrl = this.baseUrl) {
        const path =  `${baseUrl}/VendorAccount/SendOTPEmail/${email}`;
        return from(axios.post(path)).pipe(map(res => res.data));
    }

    verifyEmailOTP(email, OTP, baseUrl = this.baseUrl) {
        const path =  `${baseUrl}/VendorAccount/ConfirmEmail/${OTP}/${email}`;
        return from(axios.put(path, null)).pipe(map(res => res.data));
    }

    register(values, vendor = true) {
        const path = vendor ? `${this.baseUrl}/VendorAccount/Register` : `${this.baseUrl}/VendorAccount/Client/Register`
        const {legalName, email, password, phoneNumber} = values;
        const payload = {  
            nameAsPerTradeLicense: legalName,
            firstName: legalName,
            email: email,
            requirePhoneNumberConfirmation: false,
            password: password,
            phoneNumber: phoneNumber
        }
        
        return from(axios.post(path, payload)).pipe(map(res => res.data));
    }
}

export const becomeASellerService = new BecomeASellerService();