import axios from "axios";
import { catchError, finalize, from, map, of } from "rxjs";
import { environment } from "../environment";

class TeamService {

    isLoading = true;
    baseUrl = environment.baseUrl;

    getTeam(endPoint, parms = '', baseUrl = this.baseUrl) {
        this.isLoading = true;
        const path =  `${baseUrl}/${endPoint}?${parms}`;
        return from(axios.get(path))
        .pipe(
            map(res => res.data.Result),
            catchError(() => of([])),
            finalize(() => this.isLoading = false)
        );
    }
}

export const teamService = new TeamService();