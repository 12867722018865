import React, { useState, useEffect } from 'react'
import Logo from '../Resources/Logo.png'
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";
import { Route, Routes, Link } from 'react-router-dom';
import './index.css'

const Featurecard2 = ({ item }) => {

    return (

        <div className='flex flex-col items-center justify-center bg-main-bg-polygongrey hover:bg-main-bg-polygongolden min-h-[280px] sm:min-h-[180px] max-h-[280px] md:max-h-[320px] md:min-h-[320px] sm:max-h-[180px] min-w-[220px] md:max-w-[240px] md:min-w-[240px] sm:min-w-[120px] max-w-[220px] sm:max-w-[120px] bg-contain bg-no-repeat mr-1 px-5 text-[#000000]'>

            <p className=' text-[16px] sm:text-[10px] md:text-[16px] font-outfit font-bold w-full text-center -mt-8'>{item.label}</p>

        </div>
    )
}

export default Featurecard2



