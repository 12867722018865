import React, { useMemo } from "react";

import { Feed } from "./feed";
import Breadcrumbs from "../../Components/Reuseable/Breadcrumbs";

import OwlCarousel from 'react-owl-carousel';

const options = {
    items: 3,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
};

export const FeedDetail = ({data, pageName, route}) => {
    const {feedDetail, similarFeeds} = data;
	
	const title = useMemo(() => pageName.toLowerCase(), [pageName])
    return (
		<main className='xl:pt-20 bg-[#FFFFFF]'>
			<div className='w-[90%] xl:w-[80%] mx-auto xl:flex pt-24 xl:pt-14'>
					<div className='w-full flex flex-col justify-between items-start'>
						<div className='w-full overflow-hidden max-h-[80vh] xl:min-h-[80vh]'>
							<img src={feedDetail.ImagePath} alt='' className='w-full h-full object-cover object-center' />
						</div>
						<div className='pt-8 flex'>
							<Breadcrumbs PageName={pageName} />
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='#2D2D2D'
								className='w-3 mx-1'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M8.25 4.5l7.5 7.5-7.5 7.5'
								/>
							</svg>
							<p className='uppercase font-outfit font-bold text-black text-[15px] xl:text-xs'>
								DETAILS
							</p>
						</div>
						<p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl mt-3 '>
							{feedDetail.Title}
						</p>
						{/* Icons */}
						<div className='flex items-center justify-between mt-4'>
							<div className='flex items-center mr-4'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									height='1em'
									viewBox='0 0 448 512'
									fill='currentColor'
									className='text-gkYellow mr-2'>
									<path d='M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z' />
								</svg>
								<p className='font-outfit'>{feedDetail.CreationDate}</p>
							</div>

							<div className='flex items-center'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									height='1em'
									viewBox='0 0 512 512'
									fill='currentColor'
									className='text-gkYellow mr-2'>
									<path d='M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z' />
								</svg>
								<p className='font-outfit'>{feedDetail.EstimatedReadingMinutes} Min Read</p>
							</div>
						</div>
						<p className='font-outfit text-black text-justify  mt-4 mb-10' dangerouslySetInnerHTML={{ __html: feedDetail.Description }} />
					</div>
			</div>

			{/* Similar Feeds */}
			{similarFeeds && similarFeeds.length ? <div className="bg-[#FEF2E1] py-10">
				<div className="w-[90%] xl:w-[83%] mx-auto">
					<h2 className="font-extrabold mb-4 ml-4 text-xl capitalize">Similar {title}</h2>
					<OwlCarousel style={{zIndex: 0}} className="owl-carousal owl-theme" {...options} mouseDrag pullDrag touchDrag dots={false}>
						{similarFeeds.map((item) => <div className="item w-full" key={item.Slug}><Feed blog={item} route={route} /></div>)}
					</OwlCarousel>
				</div>
			</div> : null}
		</main>
	);
};