import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { FeedDetail } from "../Components/Blogs/FeedDetail";

import { blogService } from "../services/blog.service";
import { Loader } from "../Components/Reuseable/loader";


export const BlogDetail = () => {

	const {slug} = useParams();
	const [isLoading, setIsloading] = useState(true);
	const [data, setData] = useState({
		blogDetail: null,
		similarBlogFeeds: []
	})

	useEffect(() => {
		if(slug) {
			setIsloading(true);
			blogService.getDetails(slug).subscribe({
				next: ([blogDetail, similarBlogFeeds]) => {
					setIsloading(false);
					setData({
						blogDetail,
                        similarBlogFeeds
                    });
				},
				error: (err) => {
					console.log(err);
				}
			})
		}
	}, [slug])

	return (
		<>
			{isLoading ? 
				<div className="h-[50vh] w-full flex justify-center items-center">
					<Loader />
				</div>
				: <FeedDetail data={{feedDetail: data.blogDetail, similarFeeds: data.similarBlogFeeds}} route="blogs" pageName="BLOGS & NEWS" />
			}
		</>
	);
};

