import React, { useState, useEffect } from "react";
import Logo from "../Resources/Logo.png";
import logo2 from "../Resources/webbeesite-logo2.png";
import Logotransparent from "../Resources/logotransparent.png";
// import Avatar from '../img/avatar.png'
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { BiMenu } from "react-icons/bi";

import { setShowpopUp } from "../Actions/actions";
import { useSelector, useDispatch } from "react-redux";

import { motion } from "framer-motion";
import { Route, Routes, Link } from "react-router-dom";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import { updateUserstable, getUSERInformation } from '../utils/firebasefunctions';
// import {app} from '../firebase.config'
import "./index.css";
// import { useStateValue } from './context/StateProvider';
// import { actionType } from './context/reducer';
const Header = () => {
  const dispatch = useDispatch();
  // const firebaseAuth = getAuth(app);
  // const provider = new GoogleAuthProvider();
  // const [{user, cartShow, cartItems}, dispatch] = useStateValue();
  const [isMenu, setisMenu] = useState(false);

  const [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;

      const direction = scrollY > lastScrollY ? "down" : "up";
    //   console.log(direction);
      // if (direction !== scrollDirection ) {
      //   setScrollDirection(direction);
      // }
      if (window.pageYOffset == 0) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return (
    <>
      <header
        className={`fixed z-40 w-full  ${
          scrollDirection == "down" ? "bg-white" : null
        }`}
      >
        {/*web*/}
        <div
          className="hidden lg:flex w-[80%] mx-auto h-full item-center justify-between pt-3"
          data-aos="fade-down"
          data-aos-deleay="1200"
          data-aos-duration="1000"
        >
          <Link to={"/"} className="flex items-center gap-2 p-2 px-4">
            <img src={Logo} className="w-40  object-cover" alt="logo" title="Webbeesite"/>
          </Link>
          <div className="flex item-center gap-8 rounded-full p-2 px-4">
            {/* ${ scrollDirection === "down" ? "-top-24" : "top-0"} */}
            {/* {scrollDirection != "down" && */}
            <ul
              className="flex items-center gap-8"
              data-aos="fade"
              data-aos-deleay="100"
            >
              <Link className="link-underline" to={"/"}>
                <li className="header-text text-[15px] font-outfit font-bold uppercase">
                  Home
                </li>
              </Link>
              <Link className="link-underline" to={"/aboutus"}>
                <li className="header-text text-[15px] font-outfit font-bold uppercase">
                  About Us
                </li>
              </Link>
              <Link className="link-underline" to={"/webbeecart"}>
                <li className="header-text text-[15px] font-outfit font-bold uppercase">
                  WEBBEECART
                </li>
              </Link>
              <Link className="link-underline" to={"/webbeeclient"}>
                <li className="header-text text-[15px] font-outfit font-bold uppercase">
                  WEBBEE CLIENT
                </li>
              </Link>
              <Link className="link-underline" to={"/contact"}>
                <li className="header-text text-[15px] font-outfit font-bold uppercase">
                  Contact Us
                </li>
              </Link>

              <Link
                className="link-underline bg-[#962409] font-outfit font-bold text-[13px] px-6 py-3 rounded-lg text-white uppercase"
                to={"/becomeseller"}
              >
                <li className="">Become A Seller</li>
              </Link>
            </ul>
            {/* } */}
          </div>
        </div>

        {/*mobikle*/}
        <div
          className=" flex items-center pt-5 justify-center lg:hidden w-full h-full"
          data-aos="fade-down"
          data-aos-deleay="1200"
          data-aos-duration="1000"
        >
          <BiMenu
            onClick={() => {
              setisMenu(!isMenu);
            }}
            className="text-textColor text-[30px] cursor-pointer hover:text-white position absolute left-[5%] "
          />
          <Link to={"/"} className="flex items-center gap-2 px-4">
            <img src={logo2} className="w-40  object-contain" alt="logo2" />
          </Link>
          {isMenu && (
            <div className="flex flex-col w-full z-40 bg-[#2D2D2D] opacity-[40%] h-screen position absolute top-0 justify-start items-center py-4"></div>
          )}
          {isMenu && (
            <div
              className="flex flex-col w-full z-50 bg-[#2D2D2D] position absolute top-0 justify-start items-center py-4"
              data-aos="slide-down"
              data-aos-deleay="1200"
              data-aos-duration="1000"
            >
              <div className="flex items-center">
                <BiMenu
                  onClick={() => {
                    setisMenu(!isMenu);
                  }}
                  className="text-textColor text-[25px] cursor-pointer hover:text-white position absolute left-[5%] "
                />
                <Link
                  to={"/"}
                  onClick={() => {
                    setisMenu(false);
                  }}
                  className="flex items-center gap-2 p-2 px-4"
                >
                  <img
                    src={Logotransparent}
                    className="w-40  object-contain"
                    alt="logo"
                  />
                </Link>
              </div>
              <Link
                to={"/"}
                onClick={() => {
                  setisMenu(false);
                }}
                className="flex w-[90%] justify-center mb-2 bg-stone-600 hover:bg-[#F2B307] rounded-xl"
              >
                {/* <div className='mb-2 flex w-full bg-stone-600 hover:bg-[#F2B307] rounded-xl justify-center'> */}
                <p className=" text-[#FFFFFF] text-[17px] font-monrope text-center font-bold w-full py-3 px-4">
                  Home
                </p>
                {/* </div> */}
              </Link>
              {/* <div className='mb-2 flex w-[90%] bg-stone-600 hover:bg-[#F2B307] rounded-xl justify-center'> */}
              <Link
                to={"/aboutus"}
                onClick={() => {
                  setisMenu(false);
                }}
                className="flex w-[90%] justify-center mb-2 bg-stone-600 hover:bg-[#F2B307] rounded-xl"
              >
                <p className=" text-[#FFFFFF] text-[17px] font-monrope text-center font-bold w-full py-3 px-4">
                  About Us
                </p>
              </Link>
              <Link
                to={"/webbeecart"}
                onClick={() => {
                  setisMenu(false);
                }}
                className="flex w-[90%] justify-center mb-2 bg-stone-600 hover:bg-[#F2B307] rounded-xl"
              >
                <p className=" text-[#FFFFFF] text-[17px] font-monrope text-center font-bold w-full py-3 px-4">
                  WEBBEECART
                </p>
              </Link>
              <Link
                to={"/webbeeclient"}
                onClick={() => {
                  setisMenu(false);
                }}
                className="flex w-[90%] justify-center mb-2 bg-stone-600 hover:bg-[#F2B307] rounded-xl"
              >
                <p className=" text-[#FFFFFF] text-[17px] font-monrope text-center font-bold w-full py-3 px-4">
                  WEBBEE CLIENT
                </p>
              </Link>
              {/* </div> */}
              {/* <div className='mb-2 flex w-[90%] bg-stone-600 hover:bg-[#F2B307] rounded-xl justify-center'> */}
              <Link
                to={"/contact"}
                onClick={() => {
                  setisMenu(false);
                }}
                className="flex w-[90%] justify-center mb-2 bg-stone-600 hover:bg-[#F2B307] rounded-xl"
              >
                <p className=" text-[#FFFFFF] text-[17px] font-monrope text-center font-bold w-full py-3 px-4">
                  Contact Us
                </p>
              </Link>
              {/* </div> */}
              {/* <div className='mb-2 flex w-[90%] bg-stone-600 hover:bg-[#F2B307] rounded-xl justify-center'> */}

              <Link
                to={"/becomeseller"}
                className="mb-2 flex w-[90%] bg-[#962409] hover:bg-[#962409] rounded-xl justify-center"
                onClick={() => {
                  setisMenu(false);
                }}
              >
                {/* <div className='flex p-2 px-4'> */}
                <p className=" text-[#FFFFFF] text-[17px] font-monrope text-center font-bold w-full py-3 px-4">
                  Become A Seller
                </p>
                {/* </div> */}
              </Link>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
