import React, { useMemo } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom';
import { Feed } from './feed';

const options = {
    items: 3,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
};

export const FeedList = ({title, tag, route, feedList}) => {

  const navigate = useNavigate();

  const [i1, i2] = useMemo(() => {
    if (title) {
        const arr = title.split(' ');
        const p2 = arr.slice(1, arr.length).join(' ');
        return [arr[0], p2]
    }
    return ['', '']
  }, [title]);

  const handleNavigation = () => {
    navigate(route);
  }

  return (
    <div className="w-full">
        <div className="pt-10 flex flex-col items-center">
            <p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl'>
                <span className={`bg-no-repeat bg-[left_bottom_0.4rem] bg-[length:100%_25%] ${route === 'blogs' ? 'bg-rectangle-yellow' : 'bg-rectangle-white'}`}>{i1}</span>
                {i2 ? <span>&nbsp;{i2}</span> : null}
            </p>
            <p className="font-outfit font-semibold w-[90%] xl:w-[34%] text-center">{tag}</p>
        </div>
        <div className="w-[90%] xl:w-[80%] mx-auto">
            <OwlCarousel style={{zIndex: 0}} className="owl-carousal owl-theme" mouseDrag pullDrag touchDrag margin={20} dots={false} {...options}>
                {feedList.map((item) => <div className="item w-full" key={item.Slug}><Feed switchColor={route !== 'blogs'} blog={item} route={route} /></div>)}
                {/* {feedList.map((item, i) => 
                    <div key={i} className="item group w-full transition-all ease-in-out bg-[#FEF2E1] hover:bg-black text-black hover:text-white rounded-xl">
                        <div className="h-full w-full flex flex-col justify-center gap-2 p-8 py-4">
                            <div className="flex flex-row">
                                <div className="flex flex-row items-center gap-2">
                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.817119 5.5H11.5827C11.7351 5.5 11.8874 5.65234 11.8874 5.80469V12.4062C11.8874 13.0918 11.3288 13.625 10.6687 13.625H1.73118C1.04563 13.625 0.512432 13.0918 0.512432 12.4062V5.80469C0.512432 5.65234 0.639385 5.5 0.817119 5.5ZM11.8874 4.38281C11.8874 4.56055 11.7351 4.6875 11.5827 4.6875H0.817119C0.639385 4.6875 0.512432 4.56055 0.512432 4.38281V3.46875C0.512432 2.80859 1.04563 2.25 1.73118 2.25H2.94993V0.929688C2.94993 0.777344 3.07688 0.625 3.25462 0.625H4.27024C4.42259 0.625 4.57493 0.777344 4.57493 0.929688V2.25H7.82493V0.929688C7.82493 0.777344 7.95188 0.625 8.12962 0.625H9.14524C9.29759 0.625 9.44993 0.777344 9.44993 0.929688V2.25H10.6687C11.3288 2.25 11.8874 2.80859 11.8874 3.46875V4.38281Z" className="fill-black group-hover:fill-white" />
                                    </svg>
                                    <div className="opacity-80 text-xs font-medium">{item.CreationDate}</div>
                                </div>
                                <div className="flex flex-row items-center ml-4 gap-2">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.98 0.828125C10.4585 0.828125 13.2769 3.64648 13.2769 7.125C13.2769 10.6035 10.4585 13.4219 6.98 13.4219C3.50148 13.4219 0.683125 10.6035 0.683125 7.125C0.683125 3.64648 3.50148 0.828125 6.98 0.828125ZM8.42727 9.74023C8.55422 9.81641 8.75734 9.79102 8.83352 9.66406L9.56984 8.67383C9.67141 8.54688 9.62063 8.34375 9.49367 8.24219L7.89406 7.07422V3.57031C7.89406 3.41797 7.74172 3.26562 7.58938 3.26562H6.37063C6.19289 3.26562 6.06594 3.41797 6.06594 3.57031V7.86133C6.06594 7.96289 6.09133 8.03906 6.1675 8.08984L8.42727 9.74023Z" className="fill-black group-hover:fill-white" />
                                    </svg>
                                    <div className="opacity-80 text-xs font-medium">{item.EstimatedReadingMinutes} Min Read</div>
                                </div>
                            </div>
                            <div className="text-base font-bold w-[93%] line-clamp-1">{item.Title}</div>
                            <div className="opacity-80 text-[13px] font-medium line-clamp-2" dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                            <div onClick={() => navigate(`/${route}/${item.Slug}`)} className="text-gk-main-blue text-sm font-bold readMore-btn cursor-pointer">Read More</div>
                        </div>
                    </div>
                )} */}
            </OwlCarousel>
        </div>
        <div className="w-full flex justify-center mt-4 mb-10 ">
            <div className="pb-1 bg-white rounded-lg">
                <button onClick={() => handleNavigation()} className="w-fit whitespace-nowrap text-white flex flex-row justify-center rounded-lg px-6 py-4 text-[18px] xl:text-sm font-manrope font-bold transition-all ease-out bg-black cursor-pointer">
                    Show All
                </button>
            </div>
        </div>
    </div>       
  )
}
