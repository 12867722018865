import React from 'react'

const StepsTile = (props) => {
    return (
        <div className='min-w-[90%] max-w-[90%] md:max-w-[48%] md:min-w-[48%] xl:min-w-[32%] xl:max-w-[32%] xl:w-[32%] xl:my-6 flex items-center mr-2 ml-2 xl:ml-0 xl:mr-0 xl:justify-between px-6 xl:px-7 py-6 border-2 border-[#000000]/80 rounded-md my-3'>
            <div className='w-[30%]'>
                <img className='w-[70px]' alt='' src={props.Image} />
            </div>
            <div className='w-[70%] flex flex-col mt-[-15px] ml-3 xl:ml-0'>
                <p className='font-raleway font-extrabold text-[26px] text-[#000000]'>
                    {props.Count}
                </p>
                <h1 className='font-outfit font-bold xl:pr-20 text-xs text-[#000000] mt-[-5px]'>
                    {props.Title}
                </h1>
            </div>
        </div>  
    )
}

export default StepsTile