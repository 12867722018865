import React, { useState, useEffect } from 'react'
import Logo from '../Resources/Logo.png'
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";
import { Route, Routes, Link } from 'react-router-dom';
import './index.css'

const Featurecard = ({ item, white }) => {







    return (
        <div className='flex flex-col items-center p-2 md:p-2 w-1/3 sm:w-1/5 md:w-[23%] lg:w-1/5'>
            <img src={item.img} alt={item.label} className="h-[60px] sm:h-[40px] md:h-[100px] lg:h-[75px] w-full object-contain" />
            <div className='h-[100px] flex flex-col items-center  '>
                <p className=' text-[#000000] text-[14px] md:text-[18px] lg:text-[22px] xl:text-[16px] pt-3 font-outfit font-bold w-full text-center '>{item.label}</p>
                <p className={`${white == true ? "text-[#FFFFFF]" : "text-[#000000]"} text-[30px] sm:text-[20px] lg:text-[32px] xl:text-[28px] font-raleway font-extrabold w-full text-center mt-[-7px]`}>{item.number}</p>
            </div>

        </div>
    )
}






export default Featurecard



