import React from "react";
import CarreersPopup from "../../Screens/carreerPopup";


const JobsDescription = (props) => {

	const [carreerPopup, setCarreerPopup] = React.useState(false)

	return (
		<div className='bg-[#ffbb00]/20 h-fit py-4 px-8 font-outfit rounded-lg mx-auto xl:mx-0 my-4 xl:my-0 xl:text-justify xl:w-[49.5%]'>
			{/* <p>{props.activeDescription.activeDescription}</p> */}
			<h1 className='font-extrabold text-xl'>{props.activeDescription.Title}</h1>
			<p dangerouslySetInnerHTML={{ __html: props.activeDescription.JobDescription }}>
			</p>

			<div className='xl:flex xl:justify-between xl:items-center mt-5'>
				<div className='flex items-center'>
					<div className='flex justify-center items-center mr-8'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							viewBox='0 0 448 512'
							className='mr-2 text-[#ffbb00]'
							fill='currentColor'>
							<path d='M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z' />
						</svg>
						<p className=''>{props.activeDescription.CreationDate}</p>
					</div>

					<div className='flex justify-center items-center'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							height='1em'
							fill='currentColor'
							viewBox='0 0 384 512'
							className='mr-2 text-[#ffbb00]'>
							<path d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z' />
						</svg>
						<p className=''>{props.activeDescription.Location}</p>
					</div>
				</div>

				<button className='bg-black text-white font-bold py-3 px-6 rounded-lg mt-3 xl:mt-0 cursor-pointer'
					onClick={()=>{setCarreerPopup(true)}}
				>
					APPLY NOW
				</button>
			</div>

			{carreerPopup && <CarreersPopup activeJob={props.activeDescription} setCarreerPopup={setCarreerPopup}/>}
		</div>
	);
};

export default JobsDescription;
