import React from "react";
import { Route, Routes, Link } from 'react-router-dom';

const Breadcrumbs = (props) => {
	return (
		<section className="flex justify-center xl:justify-start items-center">
			<Link className="link-underline" to={"/"}>
				<p className="uppercase cursor-pointer font-outfit font-bold text-black text-[15px] xl:text-xs">Home</p>
			</Link>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				fill='none'
				viewBox='0 0 24 24'
				strokeWidth={1.5}
				stroke='#2D2D2D'
				className='w-3 mx-1'
			>
				<path
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M8.25 4.5l7.5 7.5-7.5 7.5'
				/>
			</svg>
			<p className="uppercase font-outfit font-bold text-black text-[15px] xl:text-xs">{props.PageName}</p>
		</section>
	);
};

export default Breadcrumbs;
