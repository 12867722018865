import "./App.css";
import React, { useEffect } from "react";
import { HomeScreen, Aboutus } from "./Screens";
import Contact from "./Screens/Contact";
import BecomeSeller from "./Screens/BecomeSeller";
import CreateWebsite from "./Screens/CreateWebsite";
import TermsAndConditions from "./Screens/TermsAndConditions";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import {Sitemap} from "./Screens/sitemap";
import { useSelector, useDispatch } from "react-redux";
import { Header, Footer } from "./Components";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setShowpopUp, setSuccesspopUp } from "./Actions/actions";
import WebbeeCart from "./Screens/webbeecart";
import WebbeeClient from "./Screens/webbeeclient";



import Whatsappcomponent from "./Components/Whatsappcomponent";
import BecomSellerPopup from "./Components/Reuseable/BecomSellerPopup";
import SuccessPopup from "./Components/SuccessPopup";
import Careers from "./Screens/Careers";
import {Blogs} from "./Screens/blogs"
import {BlogDetail} from "./Screens/BlogsDetail";
import Faq from "./Screens/Faq";
import CarreersPopup from "./Screens/carreerPopup";
import { NewsEvents } from "./Screens/news-events";
import { NewsDetail } from "./Screens/news-detail";

function App() {
  const location = useLocation();
  const { ShowSellerPopup, ShowSuccessPopup, websitelink, successmessage } =
    useSelector((state) => state.userReducer);

  Aos.init({
    duration: 1800,
    offset: 0,
  });
  // const fetchData = async () => {
  //   await getAllFoodItems().then((data) => {
  //    dispatch({
  //     type: actionType.SET_FOOD_ITEMS,
  //     foodItems: data
  //    })
  //   });
  // }

  useEffect(() => {
      window.scroll(0, 0);
  }, [location])

  const dispatch = useDispatch();
  return (
    <AnimatePresence exitBeforeEnter>
      <div className="w-full h-auto flex flex-col bg-primary">
        {/* <Whatsappcomponent /> */}
        <BecomSellerPopup
          onclose={() => {
            dispatch(setShowpopUp(false));
          }}
          ShowSellerPopup={ShowSellerPopup}
        />

        <SuccessPopup
          onclose={() => {
            dispatch(setSuccesspopUp(false));
          }}
          SuccessPopup={ShowSuccessPopup}
          websitelink={websitelink}
          successmessage={successmessage}
        />
        {/* <Topbar /> */}
        <Header />

        {/* mt-24  */}
        <main className="w-full">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            type="error"
            pauseOnHover
            theme="dark"
          />

          <Routes>
            <Route path="/*" element={<HomeScreen />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/becomeseller" element={<BecomeSeller />} />
            <Route path="/createwebsite" element={<CreateWebsite />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />}/>
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/blogs" element={<Blogs />}/>
            <Route path="/blogs/:slug" element={<BlogDetail/>}/>
            <Route path="/newsEvents" element={<NewsEvents />}/>
            <Route path="/newsEvents/:slug" element={<NewsDetail />}/>
            <Route path="/sitemap" element={<Sitemap />}/>
            <Route path="/carreersPopup" element={<CarreersPopup/>}/>
            <Route path="/sitemap" element={<Sitemap/>}/>
            <Route path="/webbeeCart" element={<WebbeeCart/>}/>
            <Route path="/webbeeclient" element={<WebbeeClient/>}/>
          </Routes>
        </main>
        <Footer />
      </div>
    </AnimatePresence>
  );
}

export default App;
