export const AboutUsListData = [
    {
        Id: Math.random(),
        Content: "<b>Professionalism and Credibility:</b> A mobile-friendly web design gives your business a professional, and credible image."
    },
    {
        Id: Math.random(),
        Content: "<b>Showcasing Products and Services:</b> You can display your products and services clearly on your website with our web design agency."
    },
    {
        Id: Math.random(),
        Content: "<b>Highlighting Reviews and Testimonials:</b> Your best reviews and testimonials can be prominently featured on your custom website."
    },
    {
        Id: Math.random(),
        Content: "<b>Encouraging Customer Engagement:</b> A SEO-friendly website can encourage customers to reach out to you."
    },
    {
        Id: Math.random(),
        Content: "<b>Attracting New Customers:</b>  Through Google marketing, your website can attract new customers."
    },
    {
        Id: Math.random(),
        Content: "<b>Google Maps Integration:</b> Integrating your website with Google Maps makes it easier for people to find your location."
    },
    {
        Id: Math.random(),
        Content: "<b>Establishing Industry Presence:</b> Your website helps establish your position in the industry as we provide cheap website design in Dubai."
    },
    {
        Id: Math.random(),
        Content: "<b>Easy Website Creation and Management:</b> <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> simplifies the process of building an attractive, mobile-friendly website without any coding knowledge."
    },
    {
        Id: Math.random(),
        Content: "<b>Long-Term Success:</b> A website ensures your long-term success, and you can enjoy all these benefits without spending a fortune."
    },
    {
        Id: Math.random(),
        Content: "<b>Affordable Solution:</b> <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> offers affordable website development solution, and by following a simple DIY approach, you can get a website at a minimal price."
    }

]
export const WebbeeCartListData = [
    {
        Id: Math.random(),
        Content: "<b>Modern Store Design:</b> Your <b>WEBBEECART</b> ecommerce website templates are fully responsive to mobile devices and never struggles under large numbers of products like many other platforms do."
    },
    {
        Id: Math.random(),
        Content: "<b>Hawkeye Dashboard:</b> Our panoramic dashboard gives an easy and quick overview and tracking of the store including total users, total products, total orders, pending orders, items sold, returns, net sales, top categories, top products, and much more."
    },
    {
        Id: Math.random(),
        Content: "<b>User Management:</b> Experience the peace of mind in handling your store as you can easily add new users and assign them with certain roles. Make them Manager, Tester, Checker etc. so you can rest assure every aspect of your store has a designated person appointed."
    },
    {
        Id: Math.random(),
        Content: "<b>Detailed Product and Category Management:</b> Create detailed product pages and organize all your products as you see fit, with robust categorization. Upload products in bulk, set sale prices, and more."
    },
    {
        Id: Math.random(),
        Content: "<b>Easy-to-Use Order Management System:</b> View and fulfill all orders in a convenient dashboard, including at-a-glance order status. Track your inventory levels, orders, sales and deliveries with ease. You can also handle documents and issue invoices in one place."
    },
    {
        Id: Math.random(),
        Content: "<b>Effective Coupon and Promotion System:</b> Boost sales with extremely flexible promotions, including single- or multi-use coupon codes, timed promotions, and much more."
    },
    {
        Id: Math.random(),
        Content: "<b>Complete Suite of Marketing and SEO tools:</b> Use all the best SEO and marketing strategies available today, so you can reach new customers and keep them coming back."
    },
    {
        Id: Math.random(),
        Content: "<b>Inventory Control:</b> With Inventory Control you can reliably track your stock numbers and receive alerts when your inventory reaches low levels."
    },
    {
        Id: Math.random(),
        Content: "<b>Robust Shipping Tools:</b> Display real-time shipping rates to your customers and print labels directly from your dashboard. Keeping track of the orders and their delivery stages."
    },
    {
        Id: Math.random(),
        Content: "<b>Local/International Cards Accepted:</b> Your online store will be automatically set up to accept credit card payments via WEBBEECART integrated payment gateway, without any extra work or setup on your part. Start selling from day one!"
    },
    {
        Id: Math.random(),
        Content: "<b>Abandoned Cart Recovery Tools:</b> Use Abandoned Cart Saver to entice customers to return and complete any unfinished purchases so you don't lose the sale."
    },
    {
        Id: Math.random(),
        Content: "<b>Data Privacy and Protection:</b> Your customers' personal and payment information is completely protected and your store is safe from data breaches."
    }

]