import React, { useMemo } from 'react';

export const Pagination = ({ currentPage, count, pageOffset, onChange }) => {
  
    const totalPages = Math.ceil(count / pageOffset);

    const previousPage = () => {
        if (currentPage > 1) {
        onChange(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
        onChange(currentPage + 1);
        }
    };

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            onChange(page);
        }
    };

    const visiblePages = useMemo(() => {
        const visiblePages = [];
        for (let i = 1; i <= totalPages; i++) {
            visiblePages.push(i);
        }
        return visiblePages;
    }, [totalPages])

    return (
        <div className="flex items-center justify-center">
        <nav className="relative z-0 inline-flex shadow-sm">
            <button
            className="px-4 py-3 rounded-l-xl border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
            disabled={currentPage === 1}
            onClick={previousPage}
            >
            PREV
            </button>
            <div className="">
            {visiblePages.map((page) => (
                <button
                key={page}
                className={`px-4 py-3 border text-sm font-medium hover:border-[#F2B307] ${page === currentPage ? ' bg-[#F2B307] text-white' : 'border-gray-300 bg-white text-gray-700'}`}
                onClick={() => goToPage(page)}
                >
                {page}
                </button>
            ))}
            </div>
            <button
            className="px-4 py-3 rounded-r-xl border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
            disabled={currentPage === totalPages}
            onClick={nextPage}
            >
            NEXT
            </button>
        </nav>
        </div>
    );
};