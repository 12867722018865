import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { teamService } from '../services/teams.service';

const options = {
    items: 3,
    responsive: {
      0: {
        items: 1.7,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 3,
      },
    },
};

export const Teams = () => {

    const [teams, setTeams] = useState([]);

    useEffect(() => {

        let subscription = teamService.getTeam('api/Customer/Team', 'ForHomePage=true').subscribe({
            next: (res) => {
                setTeams([...res]);
            }
        })

        return () => {
            if(subscription && !subscription.closed) {
                subscription.unsubscribe();
                subscription = null
            }
        }
    }, [])

    return (
        <div className="w-full bg-teams bg-cover bg-no-repeat bg-center flex flex-col lg:flex-row lg:justify-between py-10 lg:py-20">
            <div className="w-[90%] lg:w-[40%] mx-auto lg:ml-10 lg:mr-0 flex flex-col gap-6 items-center justify-center px-8">
                <div className="font-outfit font-extrabold text-6xl">Our Team</div>
                <div className="font-medium text-justify">Welcome to "Our Team" section! We want you to know the friendly faces behind WEBBEESITE who make us the best website development company in Dubai. Our team is like a puzzle - each person brings something special to the table, and when we put it all together, we create amazing websites. At WEBBEESITE, our team is on a thrilling journey to become the best web designing company in Dubai. We believe in the power of teamwork, creativity, and dedication. Let's meet the key players:</div>
            </div>
            {teams && teams.length ? <div className='w-[90%] lg:w-[50%] mx-auto mt-10 lg:m-0'>
                <OwlCarousel style={{zIndex: 0}} className="owl-carousal owl-theme" mouseDrag pullDrag touchDrag loop dots={false} {...options}>
                    {teams.map((item, i) => 
                        <div className="item w-full flex flex-col items-center" key={i}>
                            <div className="rounded-full w-44 h-44 mb-4 overflow-hidden">
                                <img src={item.ImagePath} alt="" className="w-full h-full rounded-full object-cover object-center transition-all hover:scale-110" />
                            </div>
                            <div className="font-outfit font-bold text-base">{item.Name}</div>
                            <div className='text-black opacity-60 text-xs font-outfit'>{item.Designation}</div>
                        </div>
                    )}
                </OwlCarousel>
            </div> : 
            <div className="w-full h-[40vh] mt-10 bg-gray-100 flex flex-col justify-center items-center rounded-xl">
				<p className="text-gray-500 font-outfit font-bold text-xl">No Data Found</p>
				<p className="text-gray-400 font-outfit">There are no data available right now</p>
			</div>}
        </div>
    )
}
