import React, { useCallback, useEffect, useState } from 'react';

import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import successIcon from '../../Resources/success.png' 

import { Loader } from "../Reuseable/loader";
import { ConfirmationModal } from './confirmation-modal';

import { becomeASellerService } from '../../services/seller-form.service';

const displayError = (err) => {
    let message = 'Invalid Request';
    const res = err.response.data;
    if(res.StatusCode === 409) {
        message = res.Message;
    } else if (res.status === 400) {
        message = res.errors.OTP[0];
    }
    toast.error(message);
}

const VerifyInput = React.memo(({name, isVerified, timmer = 60, handleVerify, handleTimeout}) => {

    const [field] = useField(name);
    const [time, setTime] = useState(timmer);
    
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(o => o - 1);
        }, 1000)

        if(time === 0) {
            clearInterval(interval);
            if(!isVerified) {
                handleTimeout(true);
            }
        }

        return () => {
            clearInterval(interval);
        }
    }, [time, handleTimeout, isVerified])

    return (
        <div className="relative w-full">
            <Field name={name}>
            {({field, meta}) => 
                <div className="flex flex-col gap-1">
                    <input 
                        {...field}
                        type='text' 
                        disabled={isVerified}
                        placeholder='Enter code here'
                        className={`w-full py-3 xl:py-[10px] px-3  font-manrope font-bold rounded-md xl:text-xs text-black border bg-[#0000002e] disabled:cursor-not-allowed ${meta.touched && meta.error ? 'focus:border-red-500 border-red-500 placeholder-red-700' : 'border-[#B98A07] focus:border-black placeholder:text-black/40'}`} 
                    />
                    <ErrorMessage name={field.name}>
                        {(err) => <div className="text-sm text-red-500">{err}</div>}
                    </ErrorMessage>
                </div>
            }
            </Field>
            {field.value ? 
                isVerified ?
                    <div className="absolute top-0 right-4 h-full flex items-center">
                        <img src={successIcon} alt='' className="w-5 h-5" />
                    </div> :
                    <div 
                        className="absolute top-0 right-4 flex items-center h-full text-sm text-black cursor-pointer" 
                        onClick={() => {
                            handleVerify(field.value);
                        }}
                    >
                        Verify
                    </div> 
                    : 
                <div className="absolute h-full top-0 right-4 text-black text-sm flex items-center">
                    00:{time < 10 ? `0${time}` : time}
                </div>
            }
        </div>
    )
})

export const StartBuildingWebsiteForm = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [btnMeta, setBtnMeta] = useState({
    name: '',
    disabled: false
  });
  const [initialValues, setInitialValues] = useState({
    legalName: '',
    phoneNumber: '',
    phoneOTP: '',
    isPhoneOTPGenerated: false,
    isPhoneNumberVerified: false,
    email: '',
    emailOTP: '',
    isEmailOTPGenerated: false,
    isEmailVerified: false,
    password: '',
    termsAndConditions: false
  });

  const validationSchema = yup.object().shape({
    legalName: yup.string().required("Legal name requried"),
    phoneNumber: yup.number().min(9, "").required('Phone number requried'),
    isPhoneOTPGenerated: yup.boolean(),
    // phoneOTP: yup.string().when('isPhoneOTPGenerated', {
    //     is: true,
    //     then: () => yup.string()
    //     .required("Verify your phone number"),
    //     otherwise: yup.string()
    // }),
    email: yup.string().email("Enter a valid email").required('Email requried'),
    isEmailOTPGenerated: yup.boolean(),
    password: yup.string().required('Password requried').min(6, 'Must be atleast 6 characters long'),
    termsAndConditions: yup.boolean().oneOf([true, ''], 'Please check terms and conditions first'),
  })

  const handleModal = useCallback((open) => {
    setIsOpen(open);
  }, [])

  const handlePhoneOTP = useCallback((values) => {
    becomeASellerService.generatePhoneOTP(values.phoneNumber).subscribe({
        next: (res) => {
            if (res.Status === 'Success') {
                toast.success('OTP Generated Succesfully')
            }
            setInitialValues({
                ...values,
                isPhoneOTPGenerated: true
            })
        },
        error: (err) => {
            setBtnMeta({name: '', disabled: false})
            toast.error('There was an error while generating your OTP. Please try again later')
        }
    })
  }, [])

  const handlePhoneOTPVerification = (otp, values) => {
    const {phoneNumber, phoneOTP} = values;
    becomeASellerService.verifyPhoneOTP(phoneNumber, phoneOTP).subscribe({
        next: (res) => {
            toast.success(res.Message);
            setInitialValues({
                ...values, 
                isPhoneNumberVerified: true
            })
        },
        error: (err) => {
            displayError(err);
        }
    })
  }

  const handleEmailOTP = useCallback((values) => {
    becomeASellerService.generateEmailOTP(values.email).subscribe({
        next: (res) => {
            console.log(res);
            if (res.Status === 'Success') {
                toast.success(res.Message);
            }
            setInitialValues({
                ...values,
                isEmailOTPGenerated: true
            })
        },
        error: (err) => {
            setBtnMeta({name: '', disabled: false})
            toast.error('There was an error while generating your OTP. Please try again later')
        }
    })
  }, [])

  const handleEmailOTPVerification = useCallback((otp, values) => {
    const {email, emailOTP} = values;
    becomeASellerService.verifyEmailOTP(email, emailOTP).subscribe({
        next: (res) => {
            toast.success(res.Message);
            setInitialValues({
                ...values, 
                isEmailVerified: true
            })
        },
        error: (err) => {
            displayError(err);
        }
    })
  }, [])

  const handleSubmit = useCallback((values, form) => {
    becomeASellerService.register(values, false).subscribe({
        next: (res) => {
            form.setSubmitting(false);
            if (res.Status === 'Success') {
                form.resetForm();
                toast.success("Seller request submitted successfully");
                setTimeout(() => {
                    handleModal(true);
                }, 300);
            } else {
                toast.error(res.Message)
            }
        },
        error: (err) => {
            displayError(err);
            form.setSubmitting(false);
        }
    })
    setTimeout(() => form.setSubmitting(false), 2000)
  }, [handleModal])

  return (
    <>
        <div className="w-full bg-hooray-bg bg-cover bg-center bg-no-repeat rounded-xl flex flex-col gap-10 items-center justify-center my-14 xl:mt-16 py-10">
            <h1 className="font-extrabold font-outfit text-xl text-black">Start Building Your Website Now</h1>
            <div className="w-[80%] lg:w-[60%] mx-auto">
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValues} 
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, touched, isValid, isSubmitting}) => 
                    <Form className="w-full flex flex-col gap-2">
                        <div className="form-control w-full flex flex-col gap-2 font-outfit">
                            <label htmlFor='legalName' className="text-black text-xs">Bussiness Name</label>
                            <Field name="legalName">
                                {({field, meta}) => 
                                <div className="flex flex-col gap-1">
                                    <input 
                                        {...field} 
                                        type='text' 
                                        placeholder='Type Here'
                                        className={`w-full py-3 xl:py-[10px] px-3 font-manrope font-bold rounded-md xl:text-xs text-black border bg-[#0000002e] ${meta.touched && meta.error ? 'focus:border-red-500 border-red-500 placeholder-red-700' : 'border-[#B98A07] focus:border-black placeholder:text-black/40'}`} 
                                    />
                                    <ErrorMessage name={field.name}>
                                        {(err) => <div className="text-sm text-red-500">{err}</div>}
                                    </ErrorMessage>
                                </div>}
                            </Field>
                        </div>

                        {/* Phone Number */}
                        <div className='form-group w-full flex flex-col md:flex-row md:flex-wrap md:justify-between gap-4'>
                            <div className="form-control w-full md:w-[48%] flex flex-col gap-2 font-outfit">
                                <label htmlFor='phoneNumber' className="text-black text-xs">Phone Number</label>
                                <Field name="phoneNumber" type="tel">
                                    {({field, form, meta}) => 
                                        <div className="flex flex-col gap-1">
                                            <PhoneInput
                                                disabled={values.isPhoneOTPGenerated}
                                                inputClass={`!py-3 xl:!py-[10px] !w-full font-manrope font-bold form-control !rounded-md xl:!text-xs !text-black !border !bg-[#0000002e] focus:!shadow-none focus-visible:!border-2 ${meta.touched && meta.error ? 'focus:!border-red-500 !border-red-500 !placeholder-red-700' : '!border-[#B98A07] focus:!border-black'}`}
                                                buttonClass='py-[10px] text-[12px] bg-[#0000002e]'
                                                placeholder='+971-xxx-xxx-xxx'
                                                enableClickOutside
                                                country={"ae"}
                                                onlyCountries={["ae"]}
                                                showDropdown={false}
                                                enableSearch={false}
                                                countryCodeEditable={false}
                                                onBlur={() => form.setFieldTouched(field.name, true)}
                                                onChange={(v, d) => {
                                                    form.setFieldValue(field.name, v);
                                                }}
                                            />
                                            <ErrorMessage name={field.name}>
                                                {(err) => <div className="text-sm text-red-500">{err}</div>}
                                            </ErrorMessage>
                                        </div>
                                    }
                                </Field>
                            </div>
                            <div className='w-full md:w-[48%] md:mt-7 lg:mt-6'>
                                {values.isPhoneOTPGenerated ? 
                                <VerifyInput 
                                    name='phoneOTP'
                                    isVerified={values.isPhoneNumberVerified}
                                    handleVerify={(v) => handlePhoneOTPVerification(v, values)}
                                    handleTimeout={() => {
                                        setBtnMeta({name: '', disabled: false})
                                        setInitialValues({
                                            ...values,
                                            phoneOTP: '',
                                            isPhoneOTPGenerated: false
                                        })
                                    }}
                                />
                                : 
                                <button 
                                    type='button' 
                                    onClick={() => {
                                        handlePhoneOTP(values);
                                        setBtnMeta({name: 'phoneNumber', disabled: true})
                                    }} 
                                    disabled={!!(touched.phoneNumber && errors.phoneNumber) || values.phoneNumber.length !== 12 || !!(btnMeta.name === 'phoneNumber' && btnMeta.disabled)} 
                                    className="p-3 h-fit whitespace-nowrap rounded-lg bg-black text-white text-xs disabled:bg-[#a6a39d] disabled:text-black disabled:cursor-not-allowed"
                                >
                                    Generate OTP
                                </button>}
                            </div>
                        </div>

                        {/* Email */}
                        <div className='form-group w-full flex flex-col md:flex-row md:flex-wrap md:justify-between gap-4'>
                            <div className="form-control w-full md:w-[48%] flex flex-col gap-2 font-outfit">
                                <label htmlFor='email' className="text-black text-xs">Email</label>
                                <Field name="email">
                                    {({field, meta}) => 
                                    <div className="flex flex-col gap-1">
                                        <input 
                                            {...field} 
                                            type='email'
                                            placeholder='Type Here'
                                            disabled={values.isEmailOTPGenerated}
                                            className={`w-full py-3 xl:py-[10px] px-3  font-manrope font-bold rounded-md xl:text-xs text-black border bg-[#0000002e] disabled:cursor-not-allowed ${meta.touched && meta.error ? 'focus:border-red-500 border-red-500 placeholder-red-700' : 'border-[#B98A07] focus:border-black placeholder:text-black/40'}`} 
                                        />
                                        <ErrorMessage name={field.name}>
                                            {(err) => <div className="text-sm text-red-500">{err}</div>}
                                        </ErrorMessage>
                                    </div>}
                                </Field>
                            </div>
                            <div className='w-full md:w-[48%] md:mt-7 lg:mt-6'>
                                {values.isEmailOTPGenerated ? 
                                <VerifyInput 
                                    name='emailOTP'
                                    isVerified={values.isEmailVerified}
                                    handleVerify={(v) => handleEmailOTPVerification(v, values)}
                                    handleTimeout={() => {
                                        setBtnMeta({name: '', disabled: false})
                                        setInitialValues({
                                            ...values,
                                            emailOTP: '',
                                            isEmailOTPGenerated: false
                                        })
                                    }}
                                />
                                : 
                                <button 
                                    type='button' 
                                    onClick={() => {
                                        handleEmailOTP(values);
                                        setBtnMeta({name: 'email', disabled: true})
                                    }} 
                                    disabled={!!(touched.email && errors.email) || !values.email || !!(btnMeta.name === 'email' && btnMeta.disabled)} 
                                    className="p-3 h-fit whitespace-nowrap rounded-lg bg-black text-white text-xs disabled:bg-[#a6a39d] disabled:text-black disabled:cursor-not-allowed"
                                >
                                    Generate OTP
                                </button>}
                            </div>
                        </div>

                        <div className="form-control w-full flex flex-col gap-2 font-outfit">
                            <label htmlFor='password' className="text-black text-xs">Password</label>
                            <Field name="password">
                                {({field, meta}) => 
                                <div className="w-full flex flex-col gap-1">
                                    <div className="relative w-full">
                                        <input 
                                            {...field} 
                                            placeholder='Type Here' 
                                            type={visible ? 'text' : 'password'} 
                                            className={`w-full py-3 xl:py-[10px] px-3  font-manrope font-bold rounded-md xl:text-xs text-black border bg-[#0000002e] ${meta.touched && meta.error ? 'focus:border-red-500 border-red-500 placeholder-red-700' : 'border-[#B98A07] focus:border-black placeholder:text-black/40'}`} 
                                            />
                                        <div className="absolute flex items-center h-full top-0 right-4" onClick={() => setVisible(o => !o)}>
                                            {visible ? 
                                                <AiFillEye className='text-[20px] md:text-[25px]  opacity-50 cursor-pointer fill-white' />
                                                : <AiFillEyeInvisible className='text-[20px] md:text-[25px]  opacity-50 cursor-pointer fill-white' />
                                            } 
                                        </div>
                                    </div>
                                    <ErrorMessage name={field.name}>
                                        {(err) => <div className="text-sm text-red-500">{err}</div>}
                                    </ErrorMessage>
                                </div>}
                            </Field>
                        </div>
                        
                        <div className='form-control w-full flex flex-col gap-2 font-outfit'>
                            <Field name="termsAndConditions">
                                {({field}) => 
                                <div className="flex flex-col gap-1">
                                    <div className="flex flex-row">
                                        <input 
                                            {...field} 
                                            type="checkbox" 
                                            className="mr-1"
                                        />
                                        <label className="text-black text-xs" htmlFor={field.name}>
                                            I have read and agree to the 
                                            <Link to='/termsandconditions'><span className="text-black ml-2 font-bold cursor-pointer">Terms and Conditions</span></Link>
                                        </label>
                                    </div>
                                    <ErrorMessage name={field.name}>
                                        {(err) => <div className="text-sm text-red-500">{err}</div>}
                                    </ErrorMessage>
                                </div>}
                            </Field>
                        </div>

                        <div className={` bg-white mt-4 pb-1 rounded-lg`}>
                            <button 
                                type='submit'
                                disabled={!isValid || isSubmitting}
                                className="text-white w-full h-[44px] flex flex-row justify-center rounded-lg py-3 text-[18px] xl:text-sm font-manrope font-bold bg-black disabled:cursor-not-allowed disabled:opacity-70"
                            >
                                Submit
                                {isSubmitting && <Loader size={6} color='#000000' className='ml-2' />}
                            </button>
                        </div>
                        <p className='font-manrope text-[10px] md:text-sm text-black text-center py-4'>
                            Already have a Website <br></br>
                            <button
                                type='button'
                                className="text-black text-[18px] font-outfit font-bold uppercase"
                                onClick={() => window.location.href = 'https://portal.webbeesite.com/customerclient'}
                            >
                                Login
                            </button>
                        </p>
                    </Form>}
                </Formik>
            </div>
        </div>
        {isOpen && <ConfirmationModal endpoint={'CustomerClient'} onClose={handleModal} />}
    </>
  )
}
