import React, { useState } from "react";
import Validations from "../../Validations/Validations";
import { toast } from "react-toastify";
import { environment } from "../../environment";

const baseURL = environment.baseUrl;

const ContactForm = () => {
	const [fullname, setfullname] = useState("");
	const [email, setemail] = useState("");
	const [subject, setsubject] = useState("");
	const [message, setmessage] = useState("");
	const [loader, setloader] = useState(false);

	function submit() {
		if (fullname === "") {
			toast.error("Please Enter Full Name.");
		} else if (email === "") {
			toast.error("Please Enter Email.");
		} else if (Validations.validateEmail(email) !== true) {
			toast.error("Please Enter Valid Email.");
		} else if (subject === "") {
			toast.error("Please Enter Subject.");
		} else if (message === "") {
			toast.error("Please Enter Message.");
		} else {
			setloader(true);
			ContactFormAPI(fullname, email, subject, message);
		}
	}

	const ContactFormAPI = (name, email, subject, message) => {
		const requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: name,
				email: email,
				subject: subject,
				message: message,
			}),
		};

		fetch(`${baseURL}/api/Customer/Contact`, requestOptions).then(
			(response) => {
				response
					.json()
					.then((data) => {
						if (response.status === 200) {
							toast.success(data.Message);
							setfullname("");
							setemail("");
							setsubject("");
							setmessage("");
							setloader(false);
						}
					})
					.catch((badResponse) => {
						console.debug("check badresponse here => ", badResponse);
					});
			}
		);
	};

	return (
		<section className='my-14'>
			<div className='bg-[#000000] rounded-lg py-10'>
				<h1 className='text-white font-outfit font-extrabold text-2xl xl:text-xl text-center xl:text-left xl:pl-10'>
					Send us a Message
				</h1>
				<div className='w-[85%] mx-auto mt-8 xl:mt-5'>
					<div className='text-white'>
						<p className='font-manrope font-extrabold pb-2 xl:text-xs'>
							Full Name
						</p>
						<input
							value={fullname}
							onChange={(e) => setfullname(e.target.value)}
							type='text'
							placeholder='Type Here'
							className='focus:border-[#F2B307] border border-[#FFFFFF] w-full placeholder:text-[#FFFFFF]/40 py-3 xl:py-[10px] px-3 bg-[#FFFFFF26] font-manrope font-bold rounded-md xl:text-xs'
						/>
					</div>
					<div className='text-white mt-5 xl:mt-3'>
						<p className='font-manrope font-extrabold pb-2 xl:text-xs'>Email</p>
						<input
							type='text'
							value={email}
							onChange={(e) => setemail(e.target.value)}
							placeholder='Type Here'
							className='focus:border-[#F2B307] border border-[#FFFFFF] w-full xl:text-xs placeholder:text-[#FFFFFF]/40 py-3 xl:py-[10px] px-3 bg-[#FFFFFF26] font-manrope font-bold rounded-md'
						/>
					</div>
					<div className='text-[#FFFFFF] mt-5 xl:mt-3'>
						<p className='font-manrope font-extrabold pb-2 xl:text-xs'>
							Subject
						</p>
						<input
							type='text'
							placeholder='Type Here'
							value={subject}
							onChange={(e) => setsubject(e.target.value)}
							className='focus:border-[#F2B307] border border-[#FFFFFF] w-full xl:text-xs placeholder:text-[#FFFFFF]/40 xl:py-[10px] py-3 px-3 bg-[#FFFFFF26] font-manrope font-bold rounded-md'
						/>
					</div>
					<div className='text-[#FFFFFF] mt-5 xl:mt-3'>
						<p className='font-manrope font-extrabold pb-2 xl:text-xs'>
							Your Message
						</p>
						<textarea
							placeholder='Type Here'
							value={message}
							onChange={(e) => setmessage(e.target.value)}
							className='focus:border-[#F2B307] border border-[#FFFFFF] w-full xl:text-xs placeholder:text-[#FFFFFF]/40 py-3 xl:py-[10px] px-3 bg-[#FFFFFF26] font-manrope font-bold rounded-md h-24'
						></textarea>
					</div>
					<div className='flex justify-center mt-5 xl:mt-3 bg-white rounded-lg pb-[3px]'>
						<button
							type='submit'
							onClick={() => {
								submit();
							}}
							className='text-[#000000] w-full rounded-lg py-3 text-[18px] xl:text-sm font-outfit font-bold bg-[#F2B307]'
						>
							{loader ? (
								<>
									<div className="flex justify-center items-center">
										<div>
                    <svg
											aria-hidden='true'
											className='inline w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white'
											viewBox='0 0 100 101'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
												fill='currentColor'
											/>
											<path
												d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
												fill='currentFill'
											/>
										</svg>
										<span className='sr-only'>Loading...</span>
                    </div>
                    <p className="text-black font-outfit font-bold">Loading...</p>
									</div>
								</>
							) : (
								"Submit"
							)}
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
